import React from 'react';
import ClickableLabel from './ClickableLabel';
import { faEye } from '@fortawesome/free-solid-svg-icons';

// 旧版映射表
const userAbbreviationMap = {
  'Alexander Querfurth': 'AQE',
  'Luca Suen': 'LSU',
  'Delia Ana Gal': 'DGA',
  // 可在此添加更多映射
};

function SeenByLabel({ categories }) {

  const seenByCategories = categories.filter((category) =>
    category.startsWith("Seen by")
  );
  
  const seenByDetails = seenByCategories.map((category) => {
    const fullNameOrAbbreviation = category.replace("Seen by", "").trim();
    // 如果直接是缩写（如 LSU），保留原值；否则查找映射表
    return userAbbreviationMap[fullNameOrAbbreviation] || fullNameOrAbbreviation;
  });
  // 如果没有有效的 "Seen by"，直接不渲染组件
  if (seenByDetails.length === 0) {
    return null;
  }

  // 构造菜单标题
  const title = `Seen by: ${seenByDetails.join(", ")}`;

  return (
    <ClickableLabel 
      className="seen-by"  
      menuTitle="Seen By" 
      options={seenByDetails}
      icon={faEye} 
      disableOptions={true}
      title={title}>
      {seenByDetails.length}
    </ClickableLabel>
  );
}

export default SeenByLabel;
