import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "67da7c7b-7297-4c56-adb5-cf6c2c20b59f", // 客户端 ID
    authority: "https://login.microsoftonline.com/77518de8-059e-445b-b16f-b3a338b3dfe1", // 租户 ID
    redirectUri: window.location.origin, // 登录后重定向的 URL
  },
  cache: {
    cacheLocation: "localStorage", // 缓存位置，可以是 sessionStorage 或 localStorage
    storeAuthStateInCookie: true, // 解决在某些浏览器中的问题
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
