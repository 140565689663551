import React, { useEffect, useState } from 'react';
import './MailView.css';
import MailViewRecipientsContainer from './MailView/MailViewRecipientsContainer';
import MailViewAttachments from './MailView/MailViewAttachments';
import MailViewControlBar from './MailView/MailViewControlBar';
import { getEmailBodyFromDB, saveEmailBodyToDB } from '../db';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../../../store';
 
function MailView({ email }) {
  const [emailDetails, setEmailDetails] = useState(null);
  const dispatch = useDispatch();
  const username = useSelector((state) => state.user.name);
  const [theme, setTheme] = useState('light');
 
  useEffect(() => {
    // 获取主题设置
    const savedTheme = localStorage.getItem('theme') || 'light';
    setTheme(savedTheme);
  }, []);
 
  useEffect(() => {
    const fetchEmailDetails = async () => {
      if (email && email.id) {
        try {
          const emailData = await getEmailBodyFromDB(email.id);
 
          if (emailData) {
            setEmailDetails({
              ...email,
              ...emailData,
            });
 
          } else {
            setTimeout(() => {
              const subjectPreview = email.subject ? email.subject.substring(0, 40) + (email.subject.length > 40 ? '...' : '') : 'No subject';
              
              dispatch(showNotification({
                message: `Downloading ${subjectPreview}`,
                type: 'normal'
              }));
            }, 100);
            
            const response = await fetch('/mail/detail', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ emailId: email.id, username, emailCategories: email.categories }),
            });
            const remoteData = await response.json();
 
            setEmailDetails({
              ...email,
              body: remoteData.body,
              attachments: remoteData.attachments,
            });
 
            await saveEmailBodyToDB({
              id: email.id,
              body: remoteData.body,
              attachments: remoteData.attachments,
            });

          }
        } catch (error) {
          console.error('Error fetching email details:', error);
        }
      }
    };
 
    fetchEmailDetails();
  }, [email, dispatch]);
 
  const formatDateTime = (dateTime) => {
    if (!dateTime) return 'Unknown Date';
    const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return new Date(dateTime).toLocaleString('en-US', options);
  };
 
  const getIFrameStyles = () => {
    if (theme === 'dark') {
        return `
            body {
                background-color:var(--background-color) !important;
                color: var(--text-color) !important;
                margin: 0;
                padding: 10px 0;
                font-family: Arial, sans-serif;
            }
            * {
                color: #ebebeb !important;
                background-color: transparent !important;
                border-color: #555 !important;
            }
            a {
                color: #87ceeb !important; /* 链接颜色 */
            }
        `;
    }
    return ''; // Light 模式下不需要样式
};

 
 
  return (
    <div className="mail-view-container">
      {emailDetails ? (
        <>
          <MailViewControlBar email={email} body={emailDetails.body} />
          <div className="mail-view-header">
            <div className="mail-view-header-title"><strong>SUBJECT: </strong>{emailDetails.subject || 'No Subject'}</div>
            <div className="mail-view-header-date">{formatDateTime(emailDetails.receivedDateTime)}</div>
          </div>
          <MailViewRecipientsContainer emailDetails={emailDetails} />
          {emailDetails.attachments?.length > 0 && (
            <MailViewAttachments emailId={email.id} attachments={emailDetails.attachments} />
          )}
          {emailDetails.body ? (
            <iframe
              className="mail-view-body"
              srcDoc={`
                <!DOCTYPE html>
                <html>
                <head>
                  <style>${getIFrameStyles()}</style>
                </head>
                <body>
                  ${typeof emailDetails.body === 'string' ? emailDetails.body : emailDetails.body.content}
                </body>
                </html>
              `}
              title="Email Content"
            />
          ) : (
            <p className="mail-view-placeholder">Loading email content...</p>
          )}
        </>
      ) : (
        <p className="mail-view-placeholder">Please select an email to view details</p>
      )}
    </div>
  );
}
 
export default MailView;
 