import React from 'react';
import './SupportLabels.css';
import StatusLabel from './SupportLabels/StatusLabel';
import PersonnelLabel from './SupportLabels/PersonnelLabel';
import SeenByLabel from './SupportLabels/SeenByLabel';

function SupportLabels({ categories = [], onCategoriesChange, email, selectedMailbox, isHistory, isAutoReply}) {
  /**
   * 防御性编程：确保 `categories` 是数组
   */
  const safeCategories = Array.isArray(categories) ? categories : [];
  const handleStatusChange = async (updatedCategories) => {
    if (onCategoriesChange) {
      onCategoriesChange(updatedCategories);
    }
  
    // 调用后端API更新邮件categories
    try {
      const response = await fetch('/mail/update-email-categories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailId: email.id, // 邮件 ID
          selectedMailbox: email.fromMailbox,    // 选中的邮箱文件夹
          categories: updatedCategories,  // 更新后的 categories
        }),
      });
      if (!response.ok) {
        throw new Error('更新邮件分类失败');
      }
      console.log('邮件分类更新成功');
    } catch (error) {
      console.error('更新邮件分类时出错:', error);
    }
  };
  
  const handlePersonnelChange = async (updatedCategories) => {
    if (onCategoriesChange) {
      onCategoriesChange(updatedCategories);
    }
  
    // 调用后端API更新邮件categories
    try {
      const response = await fetch('/mail/update-email-categories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailId: email.id, // 邮件 ID
          selectedMailbox: email.fromMailbox,  // 选中的邮箱文件夹
          categories: updatedCategories,  // 更新后的 categories
        }),
      });
      if (!response.ok) {
        throw new Error('更新邮件分类失败');
      }
      console.log('邮件分类更新成功');
    } catch (error) {
      console.error('更新邮件分类时出错:', error);
    }
  };

  return (
    <div className="support-labels-container">
      {/* 左侧部分 */}
      <div className="support-labels-left">
        <div onClick={(e) => e.stopPropagation()}>
          <StatusLabel
            categories={safeCategories}
            email={email}
            onStatusChange={handleStatusChange}
            selectedMailbox={selectedMailbox}
            isHistory={isHistory}
            isAutoReply={isAutoReply}
          />
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <PersonnelLabel
            categories={safeCategories}
            email={email}
            onPersonnelChange={handlePersonnelChange}
            selectedMailbox={selectedMailbox}
          />
        </div>
      </div>
      
      {/* 右侧部分 */}
      <div className="support-labels-right" onClick={(e) => e.stopPropagation()}>
        <SeenByLabel
          categories={safeCategories}
          email={email}
          selectedMailbox={selectedMailbox}
        />
      </div>

      {/* 其他普通标签 */}
      {safeCategories
        .filter(
          (category) =>
            !category.startsWith("New") &&
            !category.startsWith("Finished by") &&
            !category.startsWith("Follow-Up") &&
            !category.startsWith("Assigned to") &&
            !category.startsWith("Processing by") &&
            !category.startsWith("SR") &&
            !category.startsWith("Done") &&
            !category.startsWith("Ongoing") &&
            !category.startsWith("Seen by")
        )
        .map((category, index) => (
          <span key={index} className="support-label">
            {category}
          </span>
        ))}
    </div>
  );
}

export default SupportLabels;
