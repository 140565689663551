import React, { useState } from 'react';
import './Settings.css';

function Settings({ onSelect }) {
  const [selectedSetting, setSelectedSetting] = useState('About'); 

  const handleSettingSelect = (setting) => {
    setSelectedSetting(setting);
    onSelect(setting); 
  };

  return (
    <div className="sidebar-settings-inner">
      <div className="sidebar-settings-title">Settings</div>
      <div className="sidebar-settings-list">
        {['About', 'Automail', 'AI behaviors', 'Themes', 'Cache'].map((item) => (
          <div
            key={item}
            className={`sidebar-settings-item ${selectedSetting === item ? 'selected' : ''}`}
            onClick={() => handleSettingSelect(item)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Settings;
