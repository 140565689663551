import React, { useState, useEffect } from 'react';
import SettingsTemplate from './SettingsTemplate';

// 全局切换 Light Theme
export const setLightTheme = () => {
  document.documentElement.setAttribute('data-theme', 'light');
  localStorage.setItem('theme', 'light');
};

// 全局切换 Dark Theme
export const setDarkTheme = () => {
  document.documentElement.setAttribute('data-theme', 'dark');
  localStorage.setItem('theme', 'dark');
};

// 全局切换 Sandstone Theme
export const setSandstoneTheme = () => {
  document.documentElement.setAttribute('data-theme', 'sandstone');
  localStorage.setItem('theme', 'sandstone');
};

function Themes() {
  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'light');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <SettingsTemplate
      title="Theme Settings"
      description="Choose your theme for the application."
      buttons={[
        {
          label: 'Light',
          className: theme === 'light' ? '' : 'secondary', // 未选中时使用辅助按钮样式
          onClick: () => {
            setLightTheme();
            setTheme('light');
          },
        },
        {
          label: 'Dark',
          className: theme === 'dark' ? '' : 'secondary', // 未选中时使用辅助按钮样式
          onClick: () => {
            setDarkTheme();
            setTheme('dark');
          },
        },
        {
          label: 'Sandstone',
          className: theme === 'sandstone' ? '' : 'secondary', // 未选中时使用辅助按钮样式
          onClick: () => {
            setSandstoneTheme();
            setTheme('sandstone');
          },
        },
      ]}
    />
  );
}

export default Themes;
