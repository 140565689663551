import React from 'react';
import SettingsTemplate from './SettingsTemplate';
import { deleteDatabase } from '../Mail/db';

function Cache() {
  const handleClearDatabase = async () => {
    if (window.confirm('Are you sure you want to clear the database? This action cannot be undone.')) {
      try {
        await deleteDatabase();
        console.log('Database cleared');
        alert('Database has been successfully cleared!');
      } catch (error) {
        console.error('Failed to clear the database:', error);
        alert('Failed to clear the database. Please try again.');
      }
    }
  };

  return (
    <SettingsTemplate
      title="Clear Database"
      description="This will delete all database data from your device. The Support Portal will redownload data from the server. This may take 3-5 minutes."
      buttons={[
        {
          label: 'Clear Database',
          className: 'settings-cache-clear-btn',
          onClick: handleClearDatabase,
        },
      ]}
    />
  );
}

export default Cache;
