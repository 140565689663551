// PersonnelLabel.js
import React from 'react';
import ClickableLabel from './ClickableLabel';
import {
  faIdBadge,
  faLink,
  faCheckSquare,
  faArrowLeft,
  faUsers,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { updatePersonnelLabel } from '../../dbSupportLabel'; // 确保路径正确
import { showNotification } from '../../../../../store'; // 引入显示通知的 action

// 映射用户全名到简写（旧版支持）
const userAbbreviationMap = {
  'Alexander Querfurth': 'AQE',
  'Luca Suen': 'LSU',
  'Delia Ana Gal': 'DGA',
};

// 定义所有可能的状态前缀及其优先级
const STATUS_PREFIXES = {
  MANUAL: ['Done by', 'Finished by', 'Ongoing by', 'Assigned to', 'Processing by', 'New by' ],
  AUTO: ['SR', 'Replied by'],
};

// 定义状态到 CSS 类名的映射
const STATUS_CLASS_MAP = {
  'Done': 'status-done',
  'Ongoing': 'status-ongoing',
  'New': 'status-new',
  'SR': 'status-sr',
  'Replied': 'status-replied',
  'Unknown': 'status-new',
};

// 定义状态到图标的映射
const STATUS_ICON_MAP = {
  'Done': faCheckSquare,
  'Ongoing': faIdBadge,
  'New': faUsers,
  'SR': faLink,
  'Replied': faArrowLeft,
  'Unknown': faUsers,
};

// 工具函数：提取状态信息
const extractStatus = (categories, prefixes) => {
  const normalizePrefix = (prefix) => {
    const normalizationMap = {
      'Finished by': 'Done by',
      'Processing by': 'Ongoing by',
      'Assigned to': 'Ongoing by',
    };
    return normalizationMap[prefix] || prefix;
  };

  return categories
    .filter(category => typeof category === 'string' && prefixes.some(prefix => category.includes(prefix)))
    .map(category => {
      const prefix = prefixes.find(prefix => category.includes(prefix));
      const normalizedPrefix = normalizePrefix(prefix);
      const name = category.replace(prefix, '').trim();
      return { prefix: normalizedPrefix, name };
    });
};


// 工具函数：获取简写
const getAbbreviation = (name) => {
  return userAbbreviationMap[name] || name;
};

// 工具函数：根据优先级选择状态
const selectHighestPriorityStatus = (manualStatuses, autoStatuses) => {
  // 定义手动状态的优先级顺序
  const manualPriority = ['Done', 'Ongoing', 'New'];

  // 选择手动状态
  for (let status of manualPriority) {
    const found = manualStatuses.find(s => s.prefix.startsWith(status));
    if (found) {
      return { type: 'MANUAL', status: status, name: found.name };
    }
  }

  // 如果没有手动状态，选择自动状态
  if (autoStatuses.length > 0) {
    // 定义自动状态的优先级顺序
    const autoPriority = ['SR', 'Replied'];
    for (let status of autoPriority) {
      const found = autoStatuses.find(s => s.prefix.startsWith(status));
      if (found) {
        return { type: 'AUTO', status: status, name: found.name };
      }
    }
  }

  // 如果没有任何已知状态
  return { type: 'UNKNOWN', status: 'Unknown', name: '' };
};

function PersonnelLabel({ categories, email, selectedMailbox, onPersonnelChange }) {
  const dispatch = useDispatch();

  // 提取手动和自动状态
  const manualStatuses = extractStatus(categories, STATUS_PREFIXES.MANUAL);
  const autoStatuses = extractStatus(categories, STATUS_PREFIXES.AUTO);

  // 选择最高优先级的状态
  const selectedStatus = selectHighestPriorityStatus(manualStatuses, autoStatuses);

  let finalLabel = '';
  let finalClassKey = '';
  let icon = faUsers; // 默认图标

  switch (selectedStatus.status) {
    case 'Done':
      finalLabel = getAbbreviation(selectedStatus.name);
      finalClassKey = STATUS_CLASS_MAP['Done'];
      icon = STATUS_ICON_MAP['Done'];
      break;
    case 'Ongoing':
      finalLabel = getAbbreviation(selectedStatus.name);
      finalClassKey = STATUS_CLASS_MAP['Ongoing'];
      icon = STATUS_ICON_MAP['Ongoing'];
      break;
    case 'New':
      finalLabel = getAbbreviation(selectedStatus.name);
      finalClassKey = STATUS_CLASS_MAP['New'];
      icon = STATUS_ICON_MAP['New'];
      break;
    case 'SR':
      finalLabel = getAbbreviation(selectedStatus.name);
      finalClassKey = STATUS_CLASS_MAP['SR'];
      icon = STATUS_ICON_MAP['SR'];
      break;
    case 'Replied':
      finalLabel = getAbbreviation(selectedStatus.name);
      finalClassKey = STATUS_CLASS_MAP['Replied'];
      icon = STATUS_ICON_MAP['Replied'];
      break;
    default:
      finalLabel = '...';
      finalClassKey = STATUS_CLASS_MAP['Unknown'];
      icon = STATUS_ICON_MAP['Unknown'];
  }

  // 定义人员选项，基于名称到简写的映射
  const personnelOptions = Object.values(userAbbreviationMap);

  const handlePersonnelSelect = async (selectedValue) => {
    if (!email || !selectedMailbox) {
      console.warn('未提供 email 或 selectedMailbox，无法更新标签');
      return;
    }
  
    const isAbbreviation = Object.values(userAbbreviationMap).includes(selectedValue);
    const abbreviation = isAbbreviation
      ? selectedValue
      : userAbbreviationMap[selectedValue] || selectedValue;
  
    // 防止重复选择已存在的人员
    if (abbreviation === finalLabel) {
      dispatch(
        showNotification({
          message: `Personnel "${abbreviation}" is already applied.`,
          type: 'warning',
        })
      );
      return;
    }
  
    try {
      const updatedCategories = await updatePersonnelLabel(
        selectedMailbox,
        email.id,
        abbreviation
      );
  
      // 通知父组件更新状态
      if (onPersonnelChange) {
        onPersonnelChange(updatedCategories);
      }
  
      // 触发通知，显示当前所有标签
      if (Array.isArray(updatedCategories)) {
        dispatch(
          showNotification({
            message: `[DEBUG]update to: ${updatedCategories.join(', ')}`,
            type: 'debug',
          })
        );
      } else {
        console.error('更新后的标签不是数组:', updatedCategories);
      }
    } catch (error) {
      console.error('更新 PersonnelLabel 时出错:', error);
  
      dispatch(
        showNotification({
          message: '更新人员标签时出错',
          type: 'error',
        })
      );
    }
  };
  
  // 过滤掉当前已选择的人员简写
  const filteredPersonnelOptions = personnelOptions.filter(
    (option) => option !== finalLabel
  );
  
  
  

  const title = finalLabel === "" ? "Personnel Unknown" : `Personnel: ${finalLabel}`;

  return (
    <ClickableLabel
      menuTitle="Actor Options"
      className={`personnel-badge ${finalClassKey}`}
      icon={icon}
      title={title}
      options={filteredPersonnelOptions}
      onSelect={handlePersonnelSelect}
    >
      {finalLabel} {/* 只显示人员简写 */}
    </ClickableLabel>
  );
}

export default PersonnelLabel;
