import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCheckCircle, faSync } from '@fortawesome/free-solid-svg-icons';
import { getAllEmailsFromDB } from '../SupportPortal/Mail/db'; // 根据您的项目结构调整路径
import './SupportPortalDetail.css'; 

const SupportPortalDetail = () => {
  const [newCount, setNewCount] = useState(0);
  const [ongoingCount, setOngoingCount] = useState(0);
  const [doneCount, setDoneCount] = useState(0);

  // 过滤自动回复邮件的关键词
  const autoReplyKeywords = [
    'Automatic reply',
    'Automatische Antwort',
    'Respuesta automática',
    'Réponse automatique',
  ];

  // 定义分类逻辑
  const categorizeEmails = (emails) => {
    let newC = 0;
    let ongoingC = 0;
    let doneC = 0;

    const idGroupedEmails = new Map();

    // 按照ID进行分组处理
    emails.forEach((email) => {
        
      const regexID = /ID\d{10}/;

      // 确保 email.subject 存在并且是一个字符串
      const subject = email.subject || '';  // 如果 subject 是 undefined 或 null，则赋值为空字符串
      const match = subject.match(regexID);
      const emailID = match ? match[0] : null;

      // 排除没有ID的邮件
      if (!emailID) {
        return; // 如果没有ID，跳过这封邮件
      }

      // 跳过自动回复邮件
      if (autoReplyKeywords.some((keyword) => subject.startsWith(keyword))) {
        return;
      }

      if (emailID) {
        if (!idGroupedEmails.has(emailID)) {
          idGroupedEmails.set(emailID, []);
        }
        idGroupedEmails.get(emailID).push(email);
      }
    });

    // 合并ID分组的邮件
    idGroupedEmails.forEach((group) => {
      group.sort((a, b) => new Date(b.receivedDateTime) - new Date(a.receivedDateTime)); // 根据时间排序
      let chosenEmail = group[0]; // 默认选取最新的一封邮件
      // 如果组内有自动回复邮件，选取非自动回复邮件
      if (group.length > 1) {
        const nonAutoEmail = group.find(
          (e) => !autoReplyKeywords.some((keyword) => e.subject.startsWith(keyword))
        );
        if (nonAutoEmail) {
          chosenEmail = nonAutoEmail;
        }
      }

      // 判断分类
      const categories = chosenEmail.categories || [];
      if (
        categories.includes('New') &&
        !categories.some((cat) =>
          /Ongoing|Processing|Done|Assigned|Finished|Follow-Up/.test(cat)
        )
      ) {
        newC += 1;
      } else if (
        categories.some((cat) => /Ongoing|Assigned|Processing|Follow-Up/.test(cat)) &&
        !categories.includes('New') &&
        !categories.some((cat) => /Finished|Done/.test(cat))
      ) {
        ongoingC += 1;
      } else if (categories.some((cat) => /Done|Finished/.test(cat))) {
        doneC += 1;
      }
    });

    return { newC, ongoingC, doneC };
  };

  useEffect(() => {
    const fetchAndCategorizeEmails = async () => {
      try {
        const emails = await getAllEmailsFromDB();
        if (emails.length > 0) {
          const { newC, ongoingC, doneC } = categorizeEmails(emails);
          setNewCount(newC);
          setOngoingCount(ongoingC);
          setDoneCount(doneC);
        } else {
          setNewCount(0);
          setOngoingCount(0);
          setDoneCount(0);
        }
      } catch (err) {
        console.error('Error fetching emails:', err);
      }
    };

    fetchAndCategorizeEmails(); // 仅在组件加载时调用一次
  }, []); // 只在组件首次渲染时执行

  return (
    <div className="support-cards-container">
      <div className="support-cards-grid">
        <div className="support-card">
          <FontAwesomeIcon icon={faEnvelope} className="support-card-icon" />
          <div className="support-card-title">New</div>
          <div className="support-card-count">{newCount}</div>
        </div>
        <div className="support-card">
          <FontAwesomeIcon icon={faSync} className="support-card-icon" />
          <div className="support-card-title">Ongoing</div>
          <div className="support-card-count">{ongoingCount}</div>
        </div>
        <div className="support-card">
          <FontAwesomeIcon icon={faCheckCircle} className="support-card-icon" />
          <div className="support-card-title">Done</div>
          <div className="support-card-count">{doneCount}</div>
        </div>
      </div>
    </div>
  );
};

export default SupportPortalDetail;
