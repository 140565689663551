import React from 'react';
import { useDispatch } from 'react-redux';
import { showMenu } from '../../../../../store'; // 调整路径
import { motion } from 'framer-motion'; // 导入 Framer Motion
import '../SupportLabels.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ClickableLabel({ className, icon, title, children, options = [], onSelect, menuTitle, disableOptions = false }) {
  const dispatch = useDispatch();

  // 处理点击事件，展示菜单并设置位置
  const handleClick = (event) => {
    event.stopPropagation();
    const rect = event.target.getBoundingClientRect();
    dispatch(
      showMenu({
        position: { x: rect.right, y: rect.bottom },
        options,
        title: menuTitle, // 传递菜单标题
      })
    );
    if (!disableOptions && onSelect) {
      window.onGlobalMenuSelect = onSelect;
    }
  };

  // 定义 Framer Motion 动画变体
  const animationVariants = {
    click: { scale: 0.85, transition: { duration: 0.2 } }, // 点击时缩小
  };

  return (
    <motion.div
      className={`support-label ${className}`}
      title={title}
      onClick={handleClick}
      variants={animationVariants} // 应用动画变体
      initial="hover" // 初始状态
      whileHover="hover" // 悬停时的动画
      whileTap="click" // 点击时的缩小动画
    >
      {icon && <FontAwesomeIcon icon={icon} className="support-label-icon" />}
      {children}
    </motion.div>
  );
}

export default ClickableLabel;
