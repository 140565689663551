import React, { useEffect, useState, useRef } from 'react';
import { saveAllEmailsToDB } from './Mail/db';
import { saveDeltaLinks } from './Mail/dbDeltaQuery';
import './MailInitialSSE.css';

function InitialSSE() {
  const [details, setDetails] = useState([]);
  const [totalEmails, setTotalEmails] = useState(0);
  const [visibility, setVisibility] = useState('expanded'); // 'expanded', 'minimized', 'invisible'
  const [initializing, setInitializing] = useState(false);
  const [emailsChecked, setEmailsChecked] = useState(false);
  const [emailsExist, setEmailsExist] = useState(false);
  const [emailCount, setEmailCount] = useState(0);
  const [initializationComplete, setInitializationComplete] = useState(false);
  const detailsRef = useRef(null);

  const handleStartInitialization = () => {
    setInitializing(true);

    const eventSource = new EventSource('/mail-initialize/initialize-sse');

    // 收到一批邮件
    eventSource.addEventListener('partialEmails', async (event) => {
      try {
        const newEmails = JSON.parse(event.data);
        await saveAllEmailsToDB(newEmails);
      } catch (err) {
        console.error('Error handling partialEmails:', err);
      }
    });

    // 收到进度信息
    eventSource.addEventListener('progress', (event) => {
      const update = JSON.parse(event.data);
      setDetails((prev) => [...prev, update]);
      setTotalEmails(update.totalEmails);
    });

    // **新增**：收到 deltaLinks
    eventSource.addEventListener('deltaLinks', async (event) => {
      try {
        const { deltaLinks } = JSON.parse(event.data);
        console.log('📥 [InitialSSE] Received Delta Links:', deltaLinks);
        // 保存到 IndexedDB
        await saveDeltaLinks(deltaLinks);
      } catch (err) {
        console.error('Error handling deltaLinks:', err);
      }
    });

    // 处理完成事件
    eventSource.addEventListener('done', async (event) => {
      const data = JSON.parse(event.data);
      setTotalEmails(data.totalEmails);
      setInitializing(false);
      setInitializationComplete(true);
      eventSource.close();
    });

    // 错误处理
    eventSource.addEventListener('error', (event) => {
      let errorData;
      try {
        errorData = JSON.parse(event.data);
      } catch {
        errorData = {};
      }
      console.log('Error:', errorData);
      setInitializing(false);
      eventSource.close();
    });

    eventSource.onerror = (err) => {
      console.error('SSE connection error:', err);
    };

    return () => {
      eventSource.close();
    };
  };

  useEffect(() => {
    // 检查 IndexedDB 中是否有邮件
    const checkDatabase = async () => {
      const dbRequest = indexedDB.open('mailDB-support@fraba.com');
      dbRequest.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction(['mail-support@fraba.com-emails'], 'readonly');
        const objectStore = transaction.objectStore('mail-support@fraba.com-emails');
        const emailCountRequest = objectStore.getAll();

        emailCountRequest.onsuccess = () => {
          const emails = emailCountRequest.result;
          setEmailCount(emails.length);
          setEmailsExist(emails.length > 0);
          setEmailsChecked(true);

          // 如果邮件数量超过 6500，则设置组件为不可见
          if (emails.length >= 6500) {
            setVisibility('invisible');
          }
        };

        emailCountRequest.onerror = () => {
          console.error('Error reading IndexedDB emails.');
          setEmailsChecked(true);
        };
      };

      dbRequest.onerror = () => {
        console.error('Error opening IndexedDB.');
        setEmailsChecked(true);
      };
    };

    checkDatabase();
  }, []);

  useEffect(() => {
    if (detailsRef.current) {
      detailsRef.current.scrollTop = detailsRef.current.scrollHeight;
    }
  }, [details]);

  if (visibility === 'invisible') {
    return null;
  }

  return (
    <div className={`mail-initial-container ${visibility}`}>
      {/* 第一步：检查 IndexedDB 是否有邮件 */}
      {visibility === 'expanded' && emailsChecked && !initializing && !initializationComplete && (
        <div className="mail-initial-confirmation">
          {emailsExist && emailCount < 6500 && (
            <p>
              Local database contains <strong>{emailCount}</strong> emails, which may be incomplete. Would you like to retrieve additional emails?
            </p>
          )}
          {!emailsExist && (
            <p>
              No emails found in the local database. Would you like to initialize?
              This process may take up to <strong>5 minutes</strong>. Please do not refresh or close the tab during this time.
            </p>
          )}
          <div className="mail-initial-buttons">
            <button
              className="mail-initial-confirmation-button"
              onClick={handleStartInitialization}
            >
              {emailsExist && emailCount < 6500 ? 'Continue Retrieval' : 'Initialize'}
            </button>
            <button
              className="mail-initial-cancel-button"
              onClick={() => setVisibility('invisible')}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* 第二步：初始化过程 */}
      {visibility === 'expanded' && initializing && (
        <div className="mail-initial-content">
          <div className="mail-initial-header">Initializing...</div>

          <div className="mail-initial-progress-bar">
            <div className="mail-initial-progress-bar-fill"></div>
          </div>

          <div className="mail-initial-summary">
            Total Emails: <strong>{totalEmails}</strong>
          </div>

          <div className="mail-initial-details" ref={detailsRef}>
            {details.map((detail, index) => (
              <div key={index} className="mail-initial-detail">
                📂 <strong>{detail.folder}</strong>: Page {detail.page}, Batch: {detail.emailCount}, Total: {detail.totalEmails}
              </div>
            ))}
          </div>

          <button className="mail-initial-minimize" onClick={() => setVisibility('minimized')}>
            Minimize
          </button>
        </div>
      )}

      {/* 第三步：初始化完成 */}
      {visibility === 'expanded' && initializationComplete && (
        <div className="mail-initial-completion mail-initial-confirmation">
          <p>All emails have been successfully retrieved and saved. You can now proceed.</p>
          <button
            className="mail-initial-confirmation-button"
            onClick={() => window.location.reload()}
          >
            Continue
          </button>
        </div>
      )}


      {/* 最小化视图 */}
      {visibility === 'minimized' && (
        <div className="mail-initial-pill" onClick={() => setVisibility('expanded')}>
          Total Emails: {totalEmails}
        </div>
      )}
    </div>
  );
}

export default InitialSSE;