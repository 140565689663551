import React from 'react';
import SettingsTemplate from './SettingsTemplate';

function About() {
  const updateLogs = [
    { version: '1.0.0', date: '2024-12-18', details: ['Improved labeling system'] },
  ];

  const renderUpdateLogs = () =>
    updateLogs
      .map((log) => `${log.version} - ${log.date}: ${log.details.join(', ')}`)
      .join('\n');

  return (
    <div>
      {/* Support Portal Section */}
      <SettingsTemplate
        title="Support Portal"
        description={
          <p>
            Support Portal is a powerful tool designed to assist in managing customer emails
            efficiently. With a focus on automation and user-friendly design, the app streamlines
            communication workflows, making customer support more effective and organized.
          </p>
        }
        buttons={[]}
      />

      {/* History Section */}
      <SettingsTemplate
        title="History"
        description={<pre>{renderUpdateLogs()}</pre>} // 使用 <pre> 保持换行
        buttons={[]}
      />
    </div>
  );
}

export default About;
