import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import InfoPanel from './InfoPanel';
import './SupportPortal.css';
import Notification from './Notification'; // 引入通知组件
function SupportPortal() {
  
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isInfoPanelVisible, setInfoPanelVisible] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(150);
  const [selectedData, setSelectedData] = useState({
    selectedItem: 'Mail',          
    selectedMailbox: 'inbox',      
    selectedView: 'Traditional',    
    selectedSetting: 'About',
  });

  const infoPanelWidth = 350;

  const toggleSidebar = () => setSidebarVisible(!isSidebarVisible);
  const toggleInfoPanel = () => setInfoPanelVisible(!isInfoPanelVisible);

  const handleSelectItem = (data) => {
    setSelectedData(data);  
  };

  return (
    <div className="support-portal">
      <header className="support-portal-navbar">
        <div className="support-portal-title">Support Portal</div>
        <div className="support-portal-notification">
          <Notification />
        </div>
        <div className="support-portal-nav-buttons">
          <button
            onClick={toggleSidebar}
            className={`support-portal-toggle-button ${isSidebarVisible ? 'selected' : ''}`}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333" strokeWidth="2" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="4" width="4" height="16" />
              <rect x="9" y="4" width="12" height="16" />
            </svg>
          </button>
          <button
            onClick={toggleInfoPanel}
            className={`support-portal-toggle-button ${isInfoPanelVisible ? 'selected' : ''}`}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#333" strokeWidth="2" xmlns="http://www.w3.org/2000/svg">
              <rect x="3" y="4" width="12" height="16" />
              <rect x="17" y="4" width="4" height="16" />
            </svg>
          </button>
        </div>
      </header>

      <div className="support-portal-body">
        <motion.div
          className="support-portal-sidebar"
          initial={{ x: 0 }}
          animate={{ x: isSidebarVisible ? 0 : -sidebarWidth }}
          transition={{
            type: "spring",
            stiffness: 300, 
            damping: 35,   
          }}
          style={{ width: sidebarWidth }}
        >
          <Sidebar setSidebarWidth={setSidebarWidth} onSelectItem={handleSelectItem} />
        </motion.div>

        <motion.div
          className="support-portal-main-content"
          animate={{
            marginLeft: isSidebarVisible ? sidebarWidth : 0,
            marginRight: isInfoPanelVisible ? infoPanelWidth : 0,
          }}
          transition={{
            type: "spring",
            stiffness: 300, 
            damping: 35,   
          }}
          style={{ flexGrow: 1 }}
        >
          <MainContent selectedData={selectedData} />  
        </motion.div>

        <motion.div
          className="support-portal-info-panel"
          initial={{ x: 0 }}
          animate={{ x: isInfoPanelVisible ? 0 : infoPanelWidth }}
          transition={{
            type: "spring",
            stiffness: 300, 
            damping: 35,    
          }}
          style={{ width: infoPanelWidth }}
        >
          <InfoPanel />
        </motion.div>
      </div>
    </div>
  );
}

export default SupportPortal;
