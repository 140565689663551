import { openDB } from 'idb';
import mainEmail from '../config';

const dbName = `mailDB-${mainEmail}`; // 使用动态数据库名称
const dbVersion = 2;

const dbPromise = openDB(dbName, dbVersion, {
    upgrade(db, oldVersion) {
      const emailStorePrefix = `mail-${mainEmail}`;
  
      // 创建对象存储，确保名称符合实际需求
      if (!db.objectStoreNames.contains(`${emailStorePrefix}-emails`)) {
        db.createObjectStore(`${emailStorePrefix}-emails`, { keyPath: 'id' });
      }
      if (!db.objectStoreNames.contains(`${emailStorePrefix}-body`)) {
        db.createObjectStore(`${emailStorePrefix}-body`, { keyPath: 'id' });
      }
  
      // 创建 config 对象存储，用于存储初始化状态和其他配置
      if (!db.objectStoreNames.contains(`${emailStorePrefix}-config`)) {
        const configStore = db.createObjectStore(`${emailStorePrefix}-config`, { keyPath: 'key' });
        
        // 初始时设置 initializationCompleted 为 false
        configStore.put({ key: 'initializationCompleted', value: false });
        configStore.put({ key: 'deltaLinks', value: {} });
        configStore.put({ key: 'syncNew', value: { inbox: 0, sentitems: 0, drafts: 0, junkemail: 0, deleteditems: 0, archive: 0 } });
        configStore.put({ key: 'supportNew', value: { inbox: 0, sentitems: 0, drafts: 0, junkemail: 0, deleteditems: 0, archive: 0 } });
        configStore.put({ key: 'supportOngoing', value: { inbox: 0, sentitems: 0, drafts: 0, junkemail: 0, deleteditems: 0, archive: 0 } });
        configStore.put({ key: 'supportDone', value: { inbox: 0, sentitems: 0, drafts: 0, junkemail: 0, deleteditems: 0, archive: 0 } });
      }
    },
  });
  
  export const getAllEmailsFromDB = async () => {
    try {
      const db = await dbPromise;
      const tx = db.transaction(`mail-${mainEmail}-emails`, 'readonly');
      const store = tx.objectStore(`mail-${mainEmail}-emails`);
  
      // 获取所有邮件
      const emails = await store.getAll();
  
      return emails;
    } catch (error) {
      console.error('Error fetching all emails:', error);
      throw error; // 抛出错误以便调用者捕获
    }
  };
  

export const openDatabase = async () => {
  return dbPromise;
};

export const saveAllEmailsToDB = async (emails) => {
  const db = await dbPromise;

  // 向 emails 对象存储中插入或更新邮件
  const tx = db.transaction(`mail-${mainEmail}-emails`, 'readwrite');
  const store = tx.objectStore(`mail-${mainEmail}-emails`);

  await Promise.all(
    emails.map((email) =>
      store.put({
        id: email.id,
        ...email,
      })
    )
  );

  console.log(`Saved/Updated ${emails.length} emails in mail-${mainEmail}-emails`);
};




export const getEmailsFromAllFolders = async (limit = 100, offset = 0) => {
  const db = await dbPromise;

  // 获取 emails 对象存储中的邮件
  const tx = db.transaction(`mail-${mainEmail}-emails`, 'readonly');
  const store = tx.objectStore(`mail-${mainEmail}-emails`);

  const emails = await new Promise((resolve, reject) => {
    const results = [];
    let counter = 0;

    const request = store.openCursor();
    request.onsuccess = (event) => {
      const cursor = event.target.result;
      if (!cursor || counter >= offset + limit) {
        return resolve(results);
      }

      if (counter >= offset) {
        results.push(cursor.value);
      }

      counter += 1;
      cursor.continue();
    };

    request.onerror = (error) => {
      console.error('Error reading emails:', error);
      reject(error);
    };
  });

  console.log(
    `Fetched ${emails.length} emails from mail-${mainEmail}-emails (offset: ${offset}, limit: ${limit})`
  );

  return emails;
};


// 从指定文件夹的 IndexedDB 中获取邮件
export const getEmailsFromDB = async (folder) => {
  const db = await dbPromise;
  const tx = db.transaction(`mail-${mainEmail}-${folder}`, 'readonly');
  return await tx.store.getAll();
};

// 获取给定文件夹中的最新邮件 ID 和 receivedDateTime
export const getLastEmailInfo = async (folder) => {
    const db = await dbPromise;
    const tx = db.transaction(`mail-${mainEmail}-${folder}`, 'readonly');
    const emails = await tx.store.getAll();
  
    if (emails.length === 0) {
      console.log(`No emails found in ${folder}`);
      return { lastSyncDateTime: null, lastEmailId: null };
    }
  
    // 找到最新的邮件
    const latestEmail = emails.reduce((latest, email) => 
      new Date(email.receivedDateTime) > new Date(latest.receivedDateTime) ? email : latest,
      emails[0] // 初始值为第一封邮件
    );
  
    return {
      lastSyncDateTime: latestEmail.receivedDateTime,
      lastEmailId: latestEmail.id
    };
};

  // 更新 syncNew 配置中的邮件数量
export const updateSyncNewCount = async (folder, count) => {
    const db = await dbPromise;
    const tx = db.transaction(`mail-${mainEmail}-config`, 'readwrite');
    const syncNew = await tx.store.get('syncNew');
    
    // 更新 syncNew 对应的文件夹邮件数量
    syncNew.value[folder] = count;
    await tx.store.put({ key: 'syncNew', value: syncNew.value });
    await tx.done;
};
  
export const setInitializationStatus = async (status) => {
  const db = await dbPromise;
  const tx = db.transaction(`mail-${mainEmail}-config`, 'readwrite'); // 修正为动态名称
  await tx.store.put({ key: 'initializationCompleted', value: status });
  await tx.done;
};

export const getInitializationStatus = async () => {
  const db = await dbPromise;
  const tx = db.transaction(`mail-${mainEmail}-config`, 'readonly'); // 修正为动态名称
  const status = await tx.store.get('initializationCompleted');
  return status ? status.value : false;
};

export const getSyncNewData = async () => {
  const db = await dbPromise;
  const tx = db.transaction(`mail-${mainEmail}-config`, 'readonly'); // 修正为动态名称
  const syncNew = await tx.store.get('syncNew');
  return syncNew ? syncNew.value : {}; // 如果没有值，返回空对象
};


export const saveEmailBodyToDB = async (email) => {
  const db = await dbPromise;
  const tx = db.transaction(`mail-${mainEmail}-body`, 'readwrite');
  await tx.store.put({
    id: email.id, 
    body: email.body, 
    hasAttachments: email.hasAttachments,
    attachments: email.attachments, 
  });
  await tx.done;
};

export const getEmailBodyFromDB = async (id) => {
  const db = await dbPromise;
  const tx = db.transaction(`mail-${mainEmail}-body`, 'readonly');
  const emailData = await tx.store.get(id); // 用邮件的 id 查询

  // 如果查询到数据，返回正文和附件
  return emailData || null;
};


// 删除浏览器中所有的 IndexedDB 数据库
export const deleteDatabase = async () => {
  if (indexedDB.databases) {
    try {
      const databases = await indexedDB.databases();
      for (const db of databases) {
        await indexedDB.deleteDatabase(db.name);
        console.log(`数据库 ${db.name} 已删除`);
      }
      console.log('所有 IndexedDB 数据库已删除');
    } catch (error) {
      console.error('删除所有数据库时出错:', error);
    }
  } else {
    console.warn('浏览器不支持列出所有 IndexedDB 数据库');
  }
};

export const updateEmailCategoryInDB = async (emailId, categories) => {
  const db = await dbPromise;

  const tx = db.transaction(`mail-${mainEmail}-emails`, 'readwrite');
  const store = tx.objectStore(`mail-${mainEmail}-emails`);

  const email = await store.get(emailId);
  if (email) {
    email.categories = categories;
    await store.put(email);
    console.log(`Updated email ${emailId} categories to ${categories}`);
  } else {
    console.warn(`Email with ID ${emailId} not found in mail-${mainEmail}-emails`);
  }
};
