import React, { useState, useEffect } from 'react';
import Settings from './Sidebar/Settings';
import Mail from './Sidebar/Mail';
import { motion, AnimatePresence } from 'framer-motion';
import './Sidebar.css';

function Sidebar({ setSidebarWidth, onSelectItem }) {
  const [selectedItem, setSelectedItem] = useState('Mail'); // 默认选中 'Mail'
  const [selectedMailbox, setSelectedMailbox] = useState('inbox'); // 默认选中 Inbox
  const [selectedView, setSelectedView] = useState('Traditional'); // 默认选中 Traditional
  const [selectedSetting, setSelectedSetting] = useState('About'); // 默认选中 About
  const defaultWidth = 175;
  const secondaryMenuWidth = 175;
  const expandedWidth = defaultWidth + secondaryMenuWidth;

  useEffect(() => {
    if (selectedItem === 'Settings' || selectedItem === 'Mail') {
      setSidebarWidth(expandedWidth);
    } else {
      setSidebarWidth(defaultWidth);
    }
  }, [selectedItem, setSidebarWidth, expandedWidth, defaultWidth]);

  const handleMenuClick = (item) => {
    if (selectedItem !== item) {
      setSelectedItem(item);
      onSelectItem({
        selectedItem: item,
        selectedMailbox,
        selectedView,
        selectedSetting,
      });
    }
  };

  const handleMailSelection = (mailbox, view) => {
    if (selectedMailbox !== mailbox || selectedView !== view) {
      setSelectedMailbox(mailbox);
      setSelectedView(view);
      onSelectItem({
        selectedItem: 'Mail', // 固定传递 'Mail' 作为选中的主菜单项
        selectedMailbox: mailbox,
        selectedView: view,
        selectedSetting, // 保持当前的 Setting 选中项
      });
    }
  };

  const handleSettingSelection = (setting) => {
    if (selectedSetting !== setting) {
      setSelectedSetting(setting);
      onSelectItem({
        selectedItem: 'Settings', // 固定传递 'Settings' 作为选中的主菜单项
        selectedMailbox, // 保持当前的 Mailbox 选中项
        selectedView,    // 保持当前的 View 选中项
        selectedSetting: setting,
      });
    }
  };

  return (
    <div className="sidebar-primary-inner">
      <div className="sidebar-primary-menu">
        <div className="sidebar-primary-title">Applications</div>
        <div className="sidebar-primary-list">
          {['Mail', 'Chatbot', 'Knowledge Base', 'Settings'].map((item) => (
            <div
              key={item}
              className={`sidebar-primary-item ${selectedItem === item ? 'selected' : ''}`}
              onClick={() => handleMenuClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>

      <AnimatePresence>
        {selectedItem === 'Settings' && (
          <motion.div
            className="sidebar-secondary-menu"
            initial={{ x: -secondaryMenuWidth / 2, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -secondaryMenuWidth / 2, opacity: 0 }}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.15 },
            }}
          >
            <Settings onSelect={handleSettingSelection} />
          </motion.div>
        )}
        {selectedItem === 'Mail' && (
          <motion.div
            className="sidebar-secondary-menu"
            initial={{ x: -secondaryMenuWidth / 2, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -secondaryMenuWidth / 2, opacity: 0 }}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.15 },
            }}
          >
            <Mail onSelect={handleMailSelection} /> 
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Sidebar;
