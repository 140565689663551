import React, { useState } from 'react';
import './Applications.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHeadset, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // 导入 useNavigate
import SupportPortalDetail from './SupportPortalDetail'; // 引入新组件

const applications = [
  { name: 'Typekey+', icon: faSearch, description: 'There is nothing.', type: 'public' },
  { name: 'Support Portal', icon: faHeadset, description: 'This is the support portal for users.', type: 'internal' },
  { name: 'Knowledge DB', icon: faDatabase, description: 'There is nothing.', type: 'public' },
];

const Applications = () => {
  const [selectedApp, setSelectedApp] = useState(null);
  const userRole = useSelector((state) => state.user.role);
  const navigate = useNavigate(); // 获取 navigate 函数

  const handleMouseEnter = (app) => {
    setSelectedApp(app);
  };

  const handleClick = (app) => {
    if (app.name === 'Support Portal') {
      navigate('/support-portal'); // 跳转到 Support Portal 页面
    }
  };

  return (
    <div>
      <div className="applications-bar">
        {applications.map((app, index) => (
          (app.type === 'public' || userRole === 'internal') && (
            <div
              className={`application-item ${app.type === 'internal' ? 'internal' : ''}`} 
              key={index}
              onMouseEnter={() => handleMouseEnter(app)}
              onClick={() => handleClick(app)} // 添加点击事件
            >
              <div className="app-icon">
                <FontAwesomeIcon icon={app.icon} />
              </div>
              <div className="app-name">{app.name}</div>
            </div>
          )
        ))}
      </div>

      <div className={`application-detail ${selectedApp ? 'show' : ''}`}>
        {selectedApp ? (
          selectedApp.name === 'Support Portal' ? (
            <SupportPortalDetail /> 
          ) : (
            <div className="detail-content">
              <h3>{selectedApp.name}</h3>
              <p>{selectedApp.description}</p>
            </div>
          )
        ) : (
          <div className="detail-content">Select an application to see details</div>
        )}
      </div>
    </div>
  );
};

export default Applications;
