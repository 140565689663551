import React, { useEffect, useState, useRef } from 'react';
import './EmailsManager.css'; // 引入外部样式
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
 
function EmailsManager({ emails, selectedMailbox, children }) {
  const [processedEmails, setProcessedEmails] = useState([]);
  const [filterCategories, setFilterCategories] = useState([]);
  const [selectedOngoingTypes, setSelectedOngoingTypes] = useState([]);
  const [subjectSearch, setSubjectSearch] = useState('');
  const [isFilterMenuOpen, setFilterMenuOpen] = useState(false);
  const [senderSearch, setSenderSearch] = useState('');
  const [receiverSearch, setReceiverSearch] = useState('');
  const [ccSearch, setCcSearch] = useState('');
  const [hasAttachmentSearch, setHasAttachmentSearch] = useState(false);
  const [isImportantSearch, setIsImportantSearch] = useState(false);

  // 用于存储分批处理中间结果，避免在每次处理一小批就触发一次 setState
  const processingRef = useRef({
    uniqueEmails: [], // 专门用来收集最终要展示的邮件
  });
 
  useEffect(() => {
    // 可以在这里添加一些初始化逻辑，如果需要
  }, [emails, selectedMailbox]);
 
  useEffect(() => {
    /**
     * 1. 轻量过滤：先根据 selectedMailbox 做初步筛选
     */
    const baseFilter = () => {
      if (!emails || emails.length === 0) return [];
 
      return emails.filter((email) => {
        // 注意大小写是否匹配 (inbox vs. Inbox)
        if (selectedMailbox.toLowerCase() === 'inbox') {
          // 假设 fromMailbox 也小写，如果不是，请改成同样的大小写
          return (
            email.fromMailbox?.toLowerCase() === 'inbox' ||
            email.fromMailbox?.toLowerCase() === 'sentitems'
          );
        }
        // 其他文件夹直接匹配
        return email.fromMailbox?.toLowerCase() === selectedMailbox.toLowerCase();
      });
    };
 
    /**
     * 2. 分批处理核心逻辑
     */
    const processInChunks = (filteredEmails) => {
      // 重置中间结果
      processingRef.current.uniqueEmails = [];
 
      // 仅对 inbox 做 ID 分组处理，其他文件夹直接返回
      if (selectedMailbox.toLowerCase() !== 'inbox') {
        setProcessedEmails(filteredEmails);
        return;
      }
 
      const regexID = /ID\d{10}/;
 
      // 先进行一次“轻量级”粗筛：只对包含“ID”字样的 subject 再做正则匹配
      // 这样能减少不必要的正则开销
      const possibleIDEmails = filteredEmails.filter((email) =>
        email.subject?.includes('ID')
      );
      const noIdEmails = filteredEmails.filter(
        (email) => !email.subject?.includes('ID')
      );
 
      // 将包含“ID”的邮件再进行 chunk 分批，以防一次处理太多
      const chunkSize = 500;
      let currentIndex = 0;
 
      const idGroupedEmails = new Map();
 
      // 分批处理函数
      const processChunk = () => {
        const endIndex = Math.min(currentIndex + chunkSize, possibleIDEmails.length);
        for (let i = currentIndex; i < endIndex; i++) {
          const email = possibleIDEmails[i];
          const match = email.subject.match(regexID);
          const emailID = match ? match[0] : null;
 
          if (emailID) {
            if (!idGroupedEmails.has(emailID)) {
              idGroupedEmails.set(emailID, []);
            }
            idGroupedEmails.get(emailID).push(email);
          } else {
            noIdEmails.push(email); // subject 中虽然有 'ID' 字样，但不符合 ID\d{10} 格式
          }
        }
        currentIndex = endIndex;
 
        // 如果还有下一批，就用 setTimeout/ requestIdleCallback 继续处理
        if (currentIndex < possibleIDEmails.length) {
          // 使用 requestIdleCallback 如果可用，否则 fallback 到 setTimeout
          if (typeof requestIdleCallback !== 'undefined') {
            requestIdleCallback(processChunk);
          } else {
            setTimeout(processChunk, 0);
          }
        } else {
          // 所有 chunk 处理完毕，做后续处理
          finishProcessing(idGroupedEmails, noIdEmails);
        }
      };
 
      // 启动第一次分批处理
      processChunk();
    };
 
    /**
     * 3. 最后合并、排序、筛掉自动回复邮件
     */
    const finishProcessing = (idGroupedEmails, noIdEmails) => {
      const uniqueEmails = [];
      const autoReplyKeywords = [
        'Automatic reply',
        'Automatische Antwort',
        'Respuesta automática',
        'Réponse automatique',
      ];
 
      // 处理有 ID 的分组
      for (const [id, group] of idGroupedEmails) {
        // 根据时间排序
        group.sort((a, b) => new Date(b.receivedDateTime) - new Date(a.receivedDateTime));
        let chosenEmail = group[0];
        // 判断是否自动回复
        if (autoReplyKeywords.some((keyword) => chosenEmail.subject.startsWith(keyword)) && group.length > 1) {
          // 找到组内非自动回复的邮件
          const nonAutoEmail = group.find(
            (e) => !autoReplyKeywords.some((keyword) => e.subject.startsWith(keyword))
          );
          if (nonAutoEmail) {
            chosenEmail = nonAutoEmail;
          }
        }
        uniqueEmails.push(chosenEmail);
      }
 
      // 如果您想展示没有 ID 的邮件，可以合并进来
      // uniqueEmails.push(...noIdEmails);
 
      // 一次性更新 state
      setProcessedEmails(uniqueEmails);
    };
 
    // 先做最初步的过滤
    const filteredEmails = baseFilter();
 
    // 分批处理（inbox 需要分组，其他文件夹直接 setProcessedEmails）
    processInChunks(filteredEmails);
  }, [emails, selectedMailbox]);
 
  /**
   * 4. 分类过滤（AND 逻辑）
   */
  const applyFilters = (sourceEmails) => {
    return sourceEmails.filter((email) => {
      const categories = email.categories || [];
  
      // 1. 类别筛选
      if (filterCategories.length > 0) {
        const isCategoryMatch = filterCategories.some((category) => {
          switch (category) {
            case 'New':
              return (
                categories.includes('New') &&
                !categories.some((cat) => /^Ongoing|Processing|Done|Assigned|Finished|Follow-Up/.test(cat))
              );
            case 'Ongoing':
              return categories.some((cat) => /Ongoing|Assigned|Processing|Follow-Up/.test(cat)) &&
                     !categories.includes('New') &&
                     !categories.some((cat) => /Finished|Done/.test(cat));
            case 'Done':
              return categories.some((cat) => /Done|Finished/.test(cat));
            default:
              return false;
          }
        });
        if (!isCategoryMatch) return false;
      }
  
      // 2. Ongoing 类型筛选
      if (filterCategories.includes('Ongoing') && selectedOngoingTypes.length > 0) {
        const hasOngoing = categories.some((cat) => /Ongoing|Assigned|Processing|Follow-Up/.test(cat));
        if (hasOngoing) {
          const matchesOngoingType = selectedOngoingTypes.some((type) => {
            switch (type) {
              case 'Ongoing by AQE':
                return categories.includes('Ongoing by AQE');
              case 'Ongoing by LSU':
                return categories.includes('Ongoing by LSU');
              case 'Ongoing by DGA':
                return categories.includes('Ongoing by DGA');
              default:
                return false;
            }
          });
          if (!matchesOngoingType) return false;
        } else {
          return false;
        }
      }
  
      // 3. Subject 搜索
      if (subjectSearch.trim() !== '') {
        if (!email.subject.toLowerCase().includes(subjectSearch.trim().toLowerCase())) {
          return false;
        }
      }
  
      // 4. Sender 搜索
      if (senderSearch.trim() !== '') {
        const sender = email.from?.emailAddress;
        if (!sender || 
            !(sender.name?.toLowerCase().includes(senderSearch.trim().toLowerCase()) || 
            sender.address?.toLowerCase().includes(senderSearch.trim().toLowerCase()))) {
          return false;
        }
      }

      // 5. Receiver 搜索
      if (receiverSearch.trim() !== '') {
        const receivers = email.toRecipients || [];
        if (!receivers.some((recipient) => {
          const recipientEmail = recipient?.emailAddress;
          return recipientEmail && 
            (recipientEmail.name?.toLowerCase().includes(receiverSearch.trim().toLowerCase()) || 
            recipientEmail.address?.toLowerCase().includes(receiverSearch.trim().toLowerCase()));
        })) {
          return false;
        }
      }

      // 6. CC 搜索
      if (ccSearch.trim() !== '') {
        const ccs = email.ccRecipients || [];
        if (!ccs.some((cc) => {
          const ccEmail = cc?.emailAddress;
          return ccEmail && 
            (ccEmail.name?.toLowerCase().includes(ccSearch.trim().toLowerCase()) || 
            ccEmail.address?.toLowerCase().includes(ccSearch.trim().toLowerCase()));
        })) {
          return false;
        }
      }


      // 7. Has Attachments 搜索
      if (hasAttachmentSearch && !email.hasAttachments) {
        return false;
      }
  
      // 8. Importance 搜索
      if (isImportantSearch && email.importance !== 'high') {
        return false;
      }

      return true;
    });
  };
  
 
  /**
   * 5. 最终要展示的邮件
   */
  const filteredProcessedEmails = applyFilters(processedEmails);
 
  /**
   * 处理类别筛选点击
   */
  const handleCategoryFilterClick = (category) => {
    setFilterCategories((prev) => {
      if (prev.includes(category)) {
        // 如果取消选择 'Ongoing'，同时取消所有 Ongoing 类型筛选
        if (category === 'Ongoing') {
          setSelectedOngoingTypes([]);
        }
        return prev.filter((item) => item !== category);
      } else {
        return [...prev, category];
      }
    });
  };
 
  /**
   * 处理 Ongoing 类型筛选点击
   */
  const handleOngoingTypeClick = (type) => {
    setSelectedOngoingTypes((prev) => {
      if (prev.includes(type)) {
        return prev.filter((item) => item !== type);
      } else {
        return [...prev, type];
      }
    });
  };
 
  return (
    <div className="emails-manager-filter-wrapper">
      {/* 筛选按钮 */}
      <button
        className="emails-manager-filter-button"
        onClick={() => setFilterMenuOpen(!isFilterMenuOpen)}
      >
        <FontAwesomeIcon icon={faFilter} className="emails-manager-filter-icon" />
        Filter
      </button>
 
      {/* 筛选菜单 */}
      {isFilterMenuOpen && (
        <div className="emails-manager-filter-menu">
 
          <div className="emails-manager-filter-section">
            <div className="emails-manager-filter-title">Search Subject</div>
            <input
              type="text"
              className="emails-manager-filter-search"
              placeholder="Search by subject..."
              value={subjectSearch}
              onChange={(e) => setSubjectSearch(e.target.value)}
            />
          </div>
          {/* Sender 搜索 */}
          <div className="emails-manager-filter-section">
            <div className="emails-manager-filter-title">Search Sender</div>
            <input
              type="text"
              className="emails-manager-filter-search"
              placeholder="Search by sender..."
              value={senderSearch}
              onChange={(e) => setSenderSearch(e.target.value)}
            />
          </div>

          {/* Receiver 搜索 */}
          <div className="emails-manager-filter-section">
            <div className="emails-manager-filter-title">Search Receiver</div>
            <input
              type="text"
              className="emails-manager-filter-search"
              placeholder="Search by receiver..."
              value={receiverSearch}
              onChange={(e) => setReceiverSearch(e.target.value)}
            />
          </div>

          {/* CC 搜索 */}
          <div className="emails-manager-filter-section">
            <div className="emails-manager-filter-title">Search CC</div>
            <input
              type="text"
              className="emails-manager-filter-search"
              placeholder="Search by cc..."
              value={ccSearch}
              onChange={(e) => setCcSearch(e.target.value)}
            />
          </div>

          {/* Categories Section */}
          <div className="emails-manager-filter-section">
            <div className="emails-manager-filter-title">Categories</div>
            <label className="emails-manager-filter-option">
              <input
                type="checkbox"
                checked={filterCategories.includes('New')}
                onChange={() => handleCategoryFilterClick('New')}
              />
              {' '}New
            </label>
            <label className="emails-manager-filter-option">
              <input
                type="checkbox"
                checked={filterCategories.includes('Ongoing')}
                onChange={() => handleCategoryFilterClick('Ongoing')}
              />
              {' '}Ongoing
            </label>
            <label className="emails-manager-filter-option">
              <input
                type="checkbox"
                checked={filterCategories.includes('Done')}
                onChange={() => handleCategoryFilterClick('Done')}
              />
              {' '}Done
            </label>
          </div>
 
          {/* Ongoing Types Section */}
          {filterCategories.includes('Ongoing') && (
            <div className="emails-manager-filter-section">
              <div className="emails-manager-filter-title">Ongoing Types</div>
              <label className="emails-manager-filter-option">
                <input
                  type="checkbox"
                  checked={selectedOngoingTypes.includes('Ongoing by AQE')}
                  onChange={() => handleOngoingTypeClick('Ongoing by AQE')}
                />
                {' '}Ongoing by AQE
              </label>
              <label className="emails-manager-filter-option">
                <input
                  type="checkbox"
                  checked={selectedOngoingTypes.includes('Ongoing by DGA')}
                  onChange={() => handleOngoingTypeClick('Ongoing by DGA')}
                />
                {' '}Ongoing by DGA
              </label>
              <label className="emails-manager-filter-option">
                <input
                  type="checkbox"
                  checked={selectedOngoingTypes.includes('Ongoing by LSU')}
                  onChange={() => handleOngoingTypeClick('Ongoing by LSU')}
                />
                {' '}Ongoing by LSU
              </label>
            </div>
          )}

          {/* Has Attachments 搜索 */}
          <div className="emails-manager-filter-section">
            <div className="emails-manager-filter-title">More</div>
            <label className="emails-manager-filter-option">
              <input
                type="checkbox"
                checked={isImportantSearch}
                onChange={(e) => setIsImportantSearch(e.target.checked)}
              />
              {' '}Important
            </label>
            <label className="emails-manager-filter-option">
            <input
              type="checkbox"
              checked={hasAttachmentSearch}
              onChange={(e) => setHasAttachmentSearch(e.target.checked)}
            />
            {' '}Has Attachments
            </label>

          </div>
        </div>
      )}
 
      {/* 将筛选后的邮件传递给子组件 */}
      {children(filteredProcessedEmails, emails)}
    </div>
  );
}
 
export default EmailsManager;