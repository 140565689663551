// components/MailUpdateSSE.jsx

import { useEffect, useState, useRef } from 'react';
import { 
  saveEmailsIncrementally, 
  deleteEmailsIncrementally, 
  getDeltaLinks, 
  saveDeltaLinks,
  getAllEmailIds 
} from './Mail/dbDeltaQuery';
import { showNotification } from '../../store'; 
import { useDispatch } from 'react-redux';

function MailUpdateSSE() {
  const [clientId, setClientId] = useState(null);
  const dispatch = useDispatch();
  const eventSourceRef = useRef(null); // 用于存储当前的 EventSource 实例
  const updateIntervalRef = useRef(null); // 用于存储更新间隔的 ID

  // 使用 useRef 来存储邮件 ID Set 和计数器，以避免在每次渲染时重置
  const existingEmailIdsRef = useRef(new Set());
  const updatedCountRef = useRef(0);
  const newCountRef = useRef(0);

  useEffect(() => {
    const initializeClient = async () => {
      try {
        // 检查是否已有存储的 clientId
        let storedClientId = localStorage.getItem('clientId');
        if (!storedClientId) {
          // 初始化客户端，获取新的 clientId
          const initResponse = await fetch('/mail-incremental/init-client');
          if (!initResponse.ok) {
            throw new Error(`Failed to initialize client: ${initResponse.statusText}`);
          }
          const initData = await initResponse.json();
          storedClientId = initData.clientId;
          localStorage.setItem('clientId', storedClientId);
          console.log(`Initialized new client with clientId: ${storedClientId}`);
        }
        setClientId(storedClientId);
      } catch (err) {
        console.error('Error initializing client:', err);
      }
    };

    initializeClient();
  }, []);

  useEffect(() => {
    if (!clientId) return;

    const performUpdate = async () => {
      try {
        // 1. 获取当前存储的 Delta Links
        const currentDeltaLinks = await getDeltaLinks();
        console.log('Current Delta Links from DB:', currentDeltaLinks);

        // 2. 将 Delta Links 和 clientId 发送给后端
        const response = await fetch('/mail-incremental/update-delta-links', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ clientId, deltaLinks: currentDeltaLinks }),
        });

        if (!response.ok) {
          throw new Error(`Failed to update delta links: ${response.statusText}`);
        }

        // 3. 获取当前 IndexedDB 中所有邮件的 ID
        existingEmailIdsRef.current = await getAllEmailIds();
        console.log(`Fetched ${existingEmailIdsRef.current.size} existing email IDs from IndexedDB.`);

        // 4. 重置计数器
        updatedCountRef.current = 0;
        newCountRef.current = 0;

        // 5. 连接到 SSE，携带 clientId 作为查询参数
        if (eventSourceRef.current) {
          eventSourceRef.current.close(); // 关闭之前的连接
        }

        const eventSource = new EventSource(`/mail-incremental/update-sse?clientId=${clientId}`);
        eventSourceRef.current = eventSource;

        eventSource.addEventListener('partialEmails', async (event) => {
          try {
            const emails = JSON.parse(event.data);

            // 1. 遍历邮件，判断是新邮件还是更新邮件
            emails.forEach(email => {
              if (existingEmailIdsRef.current.has(email.id)) {
                updatedCountRef.current += 1;
              } else {
                newCountRef.current += 1;
                existingEmailIdsRef.current.add(email.id); // 添加到现有 ID Set
              }
            });

            // 2. 保存邮件到 IndexedDB
            await saveEmailsIncrementally(emails);
          } catch (err) {
            console.error('Error handling partialEmails:', err);
          }
        });

        eventSource.addEventListener('deletedEmails', async (event) => {
          try {
            const emailIds = JSON.parse(event.data);
            await deleteEmailsIncrementally(emailIds);

            // 从现有 ID Set 中移除被删除的邮件 ID
            emailIds.forEach(id => existingEmailIdsRef.current.delete(id));
          } catch (err) {
            console.error('Error handling deletedEmails:', err);
          }
        });

        eventSource.addEventListener('deltaLinks', async (event) => {
          try {
            const { deltaLinks: newDeltaLinks } = JSON.parse(event.data);
            console.log('📥 [MailUpdateSSE] Received Delta Links:', newDeltaLinks);

            // 合并新的 deltaLinks
            const updatedDeltaLinks = {
              ...currentDeltaLinks,
              ...newDeltaLinks,
            };

            // 将更新后的 Delta Links 保存到数据库
            await saveDeltaLinks(updatedDeltaLinks);

            console.log('✅ Delta Links updated and saved successfully.');
          } catch (err) {
            console.error('Error handling deltaLinks:', err);
          }
        });

        eventSource.addEventListener('progress', (event) => {
          const update = JSON.parse(event.data);
          console.log(`Progress Update: Folder ${update.folder}, Page ${update.page}, Batch ${update.emailCount}, Total ${update.totalEmails}`);
        });

        eventSource.addEventListener('done', (event) => {
          const data = JSON.parse(event.data);
          console.log(`Incremental update completed. Total Emails: ${data.totalEmails}`);

          // 生成通知消息
          let messages = [];
          if (updatedCountRef.current > 0) {
            messages.push(`${updatedCountRef.current} emails have been updated.`);
          }
          if (newCountRef.current > 0) {
            messages.push(`${newCountRef.current} new emails received.`);
          }
          const notificationMessage = messages.join(' ');

          if (notificationMessage) {
            dispatch(showNotification({ message: notificationMessage, type: 'normal' }));
          }

          eventSource.close();
        });

        eventSource.addEventListener('error', (event) => {
          let errorData;
          try {
            errorData = JSON.parse(event.data);
          } catch {
            errorData = {};
          }
          console.error('Error during incremental update:', errorData);
          eventSource.close();
        });

        eventSource.onerror = (err) => {
          console.error('SSE connection error:', err);
          eventSource.close();
        };

      } catch (err) {
        console.error('Error initiating incremental update:', err);
      }
    };

    // 立即执行一次更新
    performUpdate();

    // 设置每30秒执行一次更新
    updateIntervalRef.current = setInterval(() => {
      performUpdate();
    }, 30000); // 30000 毫秒 = 30 秒

    // 清理函数，组件卸载时关闭连接和清除定时器
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      if (updateIntervalRef.current) {
        clearInterval(updateIntervalRef.current);
      }
    };
  }, [clientId, dispatch]);

  return null; // 不渲染任何内容
}

export default MailUpdateSSE;
