import React, { useRef, useEffect, useState } from 'react';
import MailItem from './MailItem';
import { FixedSizeList as List } from 'react-window';

function MailDisplayManager({ emails: initialEmails, selectedEmail, onEmailSelect, selectedMailbox, isMobile }) {
  const itemSize = 133; // 根据你的 MailItem 高度进行调整
  const listRef = useRef();
  const [height, setHeight] = useState(600);
  const [viewedEmails, setViewedEmails] = useState([]); // 存储待同步的已浏览邮件对象
  const [emails, setEmails] = useState(initialEmails); // 本地存储邮件列表，确保视图实时更新
  const viewedEmailsRef = useRef(viewedEmails); // 用于在定时器中访问最新的 viewedEmails

  // 同步 viewedEmailsRef 当前值
  useEffect(() => {
    viewedEmailsRef.current = viewedEmails;
  }, [viewedEmails]);

  // 当父组件传入的 emails 改变时，同步到本地状态
  useEffect(() => {
    setEmails(initialEmails);
  }, [initialEmails]);

  // 更新列表高度以适应窗口大小
  useEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight - 90); // 调整偏移值
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  // 处理邮件渲染
  const handleItemsRendered = ({ visibleStartIndex, visibleStopIndex }) => {
    const emailIdsInView = new Set();

    // 获取当前可见的邮件对象
    for (let i = visibleStartIndex; i <= visibleStopIndex; i++) {
      const email = emails[i];
      if (email) {
        emailIdsInView.add(email.id);
      }
    }

    // 累加已浏览邮件
    setViewedEmails((prevViewedEmails) => {
      const updatedViewedEmails = [...prevViewedEmails];
      emailIdsInView.forEach((id) => {
        const email = emails.find((email) => email.id === id);
        if (email && !updatedViewedEmails.some((e) => e.id === email.id)) {
          updatedViewedEmails.push(email); // 将新邮件加入到已浏览列表
        }
      });
      return updatedViewedEmails;
    });
  };

  // 渲染每一行
  const Row = ({ index, style }) => {
    const email = emails[index];
    return (
      <div style={style} key={email.id}>
        <MailItem
          key={email.id}
          email={email}
          isSelected={selectedEmail?.id === email.id}
          onClick={onEmailSelect}
          isThread={false}
          selectedMailbox={selectedMailbox}
        />
      </div>
    );
  };

  return (
    <div>
      <List
        height={height}
        itemCount={emails.length}
        itemSize={itemSize}
        width="100%"
        ref={listRef}
        onItemsRendered={handleItemsRendered} // 监听渲染项
      >
        {Row}
      </List>

    </div>
  );
}

export default MailDisplayManager;
