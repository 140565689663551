import React from 'react';
import './InfoPanel.css';

function InfoPanel() {
  return (
    <div className="support-portal-info-panel">
      <div className="support-portal-info-panel-inner">
        <h2 className="support-portal-info-title">Friendly Reminder</h2>
        <p className="support-portal-info-message">
          If you encounter issues or need to reset your data, go to <strong>Settings </strong> 
          and clear the database under the <strong>Cache</strong> section. This will remove all local data and reload it from the server. 
          Please allow 3-5 minutes for the process to complete.
        </p>
      </div>
    </div>
  );
}

export default InfoPanel;
