import { openDatabase } from './db'; // 确保路径正确
import mainEmail from '../config';

const dbPromise = openDatabase();

const handleConflictingLabels = (categories = [], newStatus) => {
    // 确保 categories 是一个数组，如果为空，初始化为空数组
    if (!Array.isArray(categories)) {
        categories = [];
    }

    // 获取状态前缀（例如 "Ongoing" 或 "Done"）
    const [newStatusPrefix] = newStatus.split(' ');

    // 确保新标签不会重复添加
    const hasCurrentStatus = categories.includes(newStatus);

    // 过滤掉冲突的标签
    const updatedCategories = categories
      .filter((category) => {
        // 移除与新状态冲突的旧标签
        if (
          ["New", "Ongoing", "Done"].includes(newStatusPrefix) &&
          (category.startsWith("New") ||
           category.startsWith("Follow-Up") ||
           category.startsWith("Assigned to") ||
           category.startsWith("Finished by") ||
           category.startsWith("Ongoing") ||
           category.startsWith("Done") ||
           category.startsWith("Processing by"))
        ) {
          return false;
        }
        // 保留其他非冲突标签
        return true;
      })
      // 如果当前状态未存在，则添加它
      .concat(hasCurrentStatus ? [] : [newStatus]);

    // 返回更新后的标签，确保是一个数组
    return Array.isArray(updatedCategories) ? updatedCategories : [];
};

export const updateStatusLabel = async (folder, emailId, newStatus) => {
    try {
      const db = await dbPromise;
      const tx = db.transaction(`mail-${mainEmail}-emails`, 'readwrite');
      const email = await tx.store.get(emailId);
  
      if (!email) {
        console.warn(`邮件 ${emailId} 不存在于 ${folder}`);
        return;
      }
  
      let categories = email.categories || [];

      // 使用更新后的 handleConflictingLabels 函数
      categories = handleConflictingLabels(categories, newStatus);
  
      // 更新邮件记录到 IndexedDB
      email.categories = categories;
      await tx.store.put(email);
      console.log(`邮件 ${emailId} 的标签已更新为 ${email.categories.join(', ')}`);
      await tx.done;

      // 返回更新后的标签数组
      return email.categories;
    } catch (error) {
      console.error('更新 StatusLabel 时出错:', error);
      throw error; // 抛出异常以便父组件可以处理
    }
};
  

export const updatePersonnelLabel = async (folder, emailId, newPersonnel) => {
  try {
    const db = await dbPromise;
    const tx = db.transaction(`mail-${mainEmail}-emails`, 'readwrite');
    const store = tx.store;
    const email = await store.get(emailId);

    if (!email) {
      console.warn(`邮件 ${emailId} 不存在于 ${folder}`);
      return;
    }

    let categories = email.categories || [];

    // 定义需要更新的前缀
    const MANUAL_PREFIXES = ['Done by', 'Ongoing by', 'New by', 'Assigned to', 'Processing by', 'Finished by'];

    // 检查是否找到匹配的标签
    let foundMatchingLabel = false;

    // 更新标签：找到匹配的标签并替换人员
    categories = categories.map(category => {
      for (let prefix of MANUAL_PREFIXES) {
        if (category.includes(prefix)) {
          foundMatchingLabel = true; // 标记为找到匹配的标签
          // 替换人员部分，保持前缀
          const updatedCategory = category.replace(/by\s+(.+)/, `by ${newPersonnel}`);
          return updatedCategory; // 返回替换后的标签
        }
      }
      return category; // 如果没有匹配到，则保持原标签
    });

    // 如果没有找到任何匹配的标签，新增一个 "New by xxx"
    if (!foundMatchingLabel) {
      categories.push(`New by ${newPersonnel}`);
    }

    // 更新邮件记录到 IndexedDB
    email.categories = categories;
    await store.put(email);
    console.log(`邮件 ${emailId} 的人员标签已更新为 ${email.categories.join(', ')}`);
    await tx.done;

    // 返回更新后的标签数组
    return email.categories;
  } catch (error) {
    console.error('更新 PersonnelLabel 时出错:', error);
    throw error; // 抛出异常以便父组件可以处理
  }
};
