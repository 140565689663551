import React, { useState, useEffect, useCallback } from 'react';
import './Mail.css';
import { getSyncNewData, updateSyncNewCount } from '../Mail/db'; // 引入数据库函数

function Mail({ onSelect }) {
  const [selectedMailbox, setSelectedMailbox] = useState('inbox'); // 默认选中 Inbox
  const [selectedView, setSelectedView] = useState('Traditional'); // 默认选中 Traditional
  const [syncNewData, setSyncNewData] = useState({}); // 用于存储邮件数量
  const [lastSelectedMailbox, setLastSelectedMailbox] = useState(''); // 上次选中的文件夹

  // 映射对象：将内部值映射为显示名称
  const mailboxDisplayNames = {
    inbox: 'Inbox',
    sentitems: 'Sent',
    drafts: 'Drafts',
    archive: 'Archive',
    junkemail: 'Junk',
    deleteditems: 'Bin',
  };

  // 从 IndexedDB 获取最新的 syncNew 数据
  const fetchSyncNewData = async () => {
    const data = await getSyncNewData();
    setSyncNewData(data);
  };

  // 启动轮询，每 3 秒查询一次
  useEffect(() => {
    fetchSyncNewData(); // 组件挂载时获取数据

    const interval = setInterval(() => {
      fetchSyncNewData(); // 每3秒查询一次
    }, 3000);

    return () => clearInterval(interval); // 清除定时器，防止内存泄漏
  }, []);

  // 当文件夹变化时，处理同步新邮件数量的逻辑
  const handleMailboxSelect = useCallback(
    async (mailbox) => {
      setSelectedMailbox(mailbox);
      onSelect(mailbox, selectedView); // 通知父组件更新选中的项

      // 处理上次选择的文件夹，延迟 3 秒后清除其新邮件数量
      if (lastSelectedMailbox && lastSelectedMailbox !== mailbox) {
        setTimeout(() => {
          // 清除上次文件夹的新邮件数量
          updateSyncNewCount(lastSelectedMailbox, 0);
        }, 3000);
      }

      // 更新当前选择的文件夹的邮件数量为 0（只有在切换到新的文件夹时）
      if (syncNewData[mailbox] > 0) {
        updateSyncNewCount(mailbox, 0); // 清除新邮件数量
      }

      // 更新最后选择的文件夹
      setLastSelectedMailbox(mailbox);
    },
    [selectedView, syncNewData, lastSelectedMailbox, onSelect]
  );

  const handleViewSelect = (view) => {
    setSelectedView(view);
    onSelect(selectedMailbox, view); // 通知父组件更新选中的项
  };

  return (
    <div className="sidebar-mail-inner">
      {/* Mailbox Section */}
      <div className="sidebar-mail-section">
        <div className="sidebar-mail-title">Mailbox</div>
        <div className="sidebar-mail-list">
          {Object.keys(mailboxDisplayNames).map((item) => (
            <div
              key={item}
              className={`sidebar-mail-item ${selectedMailbox === item ? 'selected' : ''}`}
              onClick={() => handleMailboxSelect(item)} // 点击时切换选中的 Mailbox 项
            >
              <span className="sidebar-mail-item-name">{mailboxDisplayNames[item]}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Viewbox Section */}
      <div className="sidebar-mail-section">
        <div className="sidebar-mail-title">Viewbox</div>
        <div className="sidebar-mail-list">
          {['Traditional', 'Cards', 'Logfile'].map((item) => (
            <div
              key={item}
              className={`sidebar-mail-item ${selectedView === item ? 'selected' : ''}`}
              onClick={() => handleViewSelect(item)} // 点击时切换选中的 Viewbox 项
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Mail;
