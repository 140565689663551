import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import LoginScreen from './LoginScreen';
import { useIsAuthenticated } from "@azure/msal-react";
import { useSelector } from 'react-redux';
import Applications from './Applications'; // 导入新的 SupportCards 组件

function Dashboard() {
  const isAuthenticated = useIsAuthenticated();
  const user = useSelector((state) => state.user);
  const { name } = user;

  // 状态和方法来检测当前主题
  const [theme, setTheme] = useState(() => {
    return document.documentElement.getAttribute('data-theme') || 'light';
  });

  // 当主题变化时同步更新状态
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const newTheme = document.documentElement.getAttribute('data-theme');
      setTheme(newTheme || 'light');
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });

    return () => observer.disconnect();
  }, []);

  // 根据当前时间生成问候语
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) return "Good morning";
    if (currentHour >= 12 && currentHour < 18) return "Good afternoon";
    if (currentHour >= 18 && currentHour < 24) return "Good evening";
    return "It's midnight";
  };

  const greeting = getGreeting();

  // 根据认证状态设置标题
  const title = isAuthenticated
    ? name
      ? `${greeting}, ${name}`
      : "Authenticating Your Access…"
    : "Support Dashboard";
  const subtitle = isAuthenticated
    ? name
      ? "Here's a quick overview of your activities and insights."
      : "Loading your dashboard…"
    : "Here you can access our utilities";

  // 根据主题选择 logo
  const logoSrc = theme === 'dark' ? '/logo-dark.png' : '/logo-light.png';

  return (
    <div className="dashboard-support-portal-dashboard">
      {/* 页面左上角显示 Logo */}
      <div className="dashboard-logo">
        <img src={logoSrc} alt="Logo" style={{ maxWidth: '120px' }} />
      </div>

      <div key={title} className="dashboard-support-portal-fade-in">
        <div className="dashboard-support-portal-title">{title}</div>
      </div>
      <div key={subtitle} className="dashboard-support-portal-fade-in">
        <div className="dashboard-support-portal-subtitle">{subtitle}</div>
      </div>
      <LoginScreen />
      <div className="dashboard-support-portal-module">
        <Applications />  
      </div>
    </div>
  );
}

export default Dashboard;
