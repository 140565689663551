import React from 'react';
import './MailViewControlBar.css';
import { FaExternalLinkAlt, FaDownload } from 'react-icons/fa';

const MailViewControlBar = ({ email, body }) => {

  // 打开网页链接
  const handleWebLink = () => {
    if (email.webLink) {
      // 使用 window.open 打开一个指定大小的窗口
      const windowFeatures = "width=768,height=800,scrollbars=yes,resizable=yes";
      window.open(email.webLink, '_blank', windowFeatures);
      console.log(email.webLink);
    } else {
      console.log('No webLink available for this email');
    }
  };

  // 下载邮件为 .eml 文件
  const handleDownloadEmail = () => {
    if (!email) return;

    // 从 email 和 body 中获取需要的信息
    const { subject, from, toRecipients, ccRecipients, receivedDateTime } = email;

    // 生成 EML 文件的内容
    const emlContent = [
      `From: ${from.emailAddress.address}`,
      `To: ${toRecipients.map(recipient => recipient.emailAddress.address).join(', ')}`,
      // 添加 CC 字段
      ccRecipients && ccRecipients.length > 0 ? `CC: ${ccRecipients.map(recipient => recipient.emailAddress.address).join(', ')}` : '',
      `Subject: ${subject}`,
      `Date: ${new Date(receivedDateTime).toUTCString()}`,
      `Content-Type: text/html; charset=UTF-8`, // 假设邮件正文是 HTML 格式
      '',
      body.content // 使用传入的 body 内容作为邮件正文
    ].join('\r\n');

    // 创建一个 Blob 对象，用于生成 .eml 文件
    const blob = new Blob([emlContent], { type: 'message/rfc822' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${subject}.eml`); // 设置下载的文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="mail-view-control-bar">
      <div className="control-btn" data-tooltip="Open in Outlook" onClick={handleWebLink}>
        <FaExternalLinkAlt />
      </div>
      <div className="control-btn" data-tooltip="Download" onClick={handleDownloadEmail}>
        <FaDownload />
      </div>
    </div>
  );
};

export default MailViewControlBar;
