// NotFound.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css'; // 自定义样式文件

function NotFound() {
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate('/'); // 返回首页
  };

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-text">
          Oops! The page you're looking for doesn't exist.
        </p>
        <p className="not-found-text-secondary">
          It might have been removed or is temporarily unavailable.
        </p>
        <button className="not-found-button" onClick={handleBackHome}>
          Back to Homepage
        </button>
      </div>
      <div className="not-found-graphic">
        <img src="/404-illustration.svg" alt="Page Not Found" className="not-found-image" />
      </div>
    </div>
  );
}

export default NotFound;
