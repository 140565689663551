import React, { useState, useEffect, useRef } from 'react';
import MailItem from './MailItem';
import MailDisplayManager from './MailDisplayManager';
import './MailSelection.css';
import { motion, AnimatePresence } from 'framer-motion';

// Custom hook to get element size
function useElementSize(ref) {
  const [size, setSize] = useState({ width: undefined, height: undefined });

  useEffect(() => {
    if (!ref.current) return;

    const observer = new ResizeObserver(entries => {
      if (entries[0]) {
        setSize({
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
        });
      }
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return size;
}

function MailSelection({ emails, allEmails, selectedMailbox, onEmailSelect }) {
  const mailSectionRef = useRef(null);

  const [selectedEmail, setSelectedEmail] = useState(null);
  const [threadEmails, setThreadEmails] = useState([]);
  const [selectedThreadEmail, setSelectedThreadEmail] = useState(null);
  const [view, setView] = useState('emails'); // 'emails' or 'threads'

  const size = useElementSize(mailSectionRef);
  const isMobile = size.width < 600;

  useEffect(() => {
    // 清除 threadEmails，当 selectedMailbox 改变时
    setThreadEmails([]);
    setSelectedEmail(null);
    setSelectedThreadEmail(null);
    console.log('Mailbox changed. Cleared thread emails.');
  }, [selectedMailbox]);

  const sortEmailsByDate = (emails) =>
    emails.sort((a, b) => new Date(b.receivedDateTime) - new Date(a.receivedDateTime));

  const handleEmailSelect = (email) => {
    console.log("Selected email:", email);
    setSelectedEmail(email);
    setSelectedThreadEmail(null);
    onEmailSelect(email);
    if (isMobile) setView('threads');

    // 清除旧的 thread emails
    setThreadEmails([]);

    const subject = email.subject || '';
    const conversationId = email.conversationId;

    const regexID = /ID\d{10}/; // 匹配固定格式的 ID，例如 ID1234567890
    const match = subject.match(regexID);

    let firstPassEmails = [];
    if (match) {
      const foundID = match[0];
      console.log("Found ID pattern in subject:", foundID);

      // 第一步：在 allEmails 中筛选包含相同 ID 的邮件
      firstPassEmails = allEmails.filter((e) => {
        if (!e.subject) return false;
        const subjMatch = e.subject.match(regexID);
        return subjMatch && subjMatch[0] === foundID;
      });
    } else {
      firstPassEmails = allEmails; // 没有 ID 时，考虑所有邮件
    }

    // 第二步：对第一步结果通过 conversationId 再次筛选
    const finalThreads = firstPassEmails.filter((e) => e.conversationId === conversationId);

    // 去重逻辑
    const uniqueThreadsMap = new Map();
    finalThreads.forEach((e) => {
      if (!uniqueThreadsMap.has(e.id)) {
        uniqueThreadsMap.set(e.id, e);
      }
    });
    let uniqueThreads = Array.from(uniqueThreadsMap.values());

    // 排序逻辑
    uniqueThreads = sortEmailsByDate(uniqueThreads);

    setThreadEmails(uniqueThreads);
  };

  const handleThreadEmailSelect = (threadEmail) => {
    setSelectedThreadEmail(threadEmail);
    onEmailSelect(threadEmail);
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const renderNoEmails = () => (
    <div className="no-emails">
      <span>No emails</span>
    </div>
  );

  return (
    <div className={`mail-section-container ${isMobile ? 'mobile' : ''}`} ref={mailSectionRef}>
      <div className="mail-section">
        {isMobile && (
          <div className="mobile-header">
            <span
              className={`section-title ${view === 'emails' ? 'active' : ''}`}
              onClick={() => handleViewChange('emails')}
            >
              {selectedMailbox}
            </span>
            {threadEmails.length > 0 && (
              <>
                <span className="arrow">→</span>
                <span
                  className={`section-title ${view === 'threads' ? 'active' : ''}`}
                  onClick={() => handleViewChange('threads')}
                >
                  Threads ({threadEmails.length})
                </span>
              </>
            )}
          </div>
        )}

        <AnimatePresence initial={false}>
          {(!isMobile || view === 'emails') && (
            <motion.div
              key="emails-list"
              className="emails-list"
              initial={{ x: isMobile ? -50 : 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              {!isMobile && <div className="section-title">{selectedMailbox}</div>}
              {emails.length === 0
                ? renderNoEmails()
                : (
                  <MailDisplayManager
                    emails={sortEmailsByDate(emails)}
                    selectedEmail={selectedEmail}
                    onEmailSelect={handleEmailSelect}
                    selectedMailbox={selectedMailbox}
                    isMobile={isMobile}
                  />
                )}
            </motion.div>
          )}

          {((!isMobile && selectedEmail) || (isMobile && view === 'threads')) && threadEmails.length > 0 && (
            <motion.div
              key="email-threads"
              className="email-threads"
              initial={{ x: isMobile ? 50 : 0, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              {!isMobile && <div className="section-title">Threads ({threadEmails.length})</div>}
              <div className="thread-items">
                {threadEmails.map((threadEmail) => (
                  <MailItem
                    key={threadEmail.id}
                    email={threadEmail}
                    isSelected={selectedThreadEmail?.id === threadEmail.id}
                    onClick={handleThreadEmailSelect}
                    isThread
                    threadSameAsSelectedClass={selectedEmail?.id === threadEmail.id ? 'same-as-selected' : ''}
                    selectedMailbox={selectedMailbox}
                  />
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default MailSelection;
