import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './MailViewRecipientsContainer.css';

function MailViewRecipientsContainer({ emailDetails }) {
  const [isRecipientsExpanded, setIsRecipientsExpanded] = useState(false);

  const shouldShowExpandButton = () => {
    const fromName = emailDetails.from?.emailAddress?.name || '';
    const fromAddress = emailDetails.from?.emailAddress?.address || '';
    if (fromName !== fromAddress) {
      return true;
    }

    const toRecipients = emailDetails.toRecipients || [];
    const ccRecipients = emailDetails.ccRecipients || [];
    const allRecipients = [...toRecipients, ...ccRecipients];

    return allRecipients.some((recipient) => {
      const name = recipient.emailAddress?.name || '';
      const address = recipient.emailAddress?.address || '';
      return name !== address;
    });
  };

  return (
    <div className="mail-view-recipients-container">
      <div className="mail-view-recipients-row">
        <div className={`mail-view-recipient ${isRecipientsExpanded ? 'expanded' : ''}`}>
          <strong>FROM:</strong>{' '}
          {isRecipientsExpanded
            ? emailDetails.from?.emailAddress?.address || 'Unknown sender'
            : emailDetails.from?.emailAddress?.name || 'Unknown sender'}
        </div>
        <div className={`mail-view-recipient ${isRecipientsExpanded ? 'expanded' : ''}`}>
          <strong>TO:</strong>{' '}
          {emailDetails.toRecipients
            ?.map((recipient) =>
              isRecipientsExpanded
                ? recipient.emailAddress?.address || 'Unknown'
                : recipient.emailAddress?.name || 'Unknown'
            )
            .join(', ')}
        </div>
        {emailDetails.ccRecipients?.length > 0 && (
          <div className={`mail-view-recipient ${isRecipientsExpanded ? 'expanded' : ''}`}>
            <strong>CC:</strong>{' '}
            {emailDetails.ccRecipients
              ?.map((recipient) =>
                isRecipientsExpanded
                  ? recipient.emailAddress?.address || 'Unknown'
                  : recipient.emailAddress?.name || 'Unknown'
              )
              .join(', ')}
          </div>
        )}
      </div>
      {shouldShowExpandButton() && (
        <button
          className="mail-view-expand-button"
          onClick={() => setIsRecipientsExpanded(!isRecipientsExpanded)}
        >
          {isRecipientsExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      )}
    </div>
  );
}

export default MailViewRecipientsContainer;
