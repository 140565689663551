import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { hideMenu } from '../../../store';
import './GlobalMenu.css';

const GlobalMenu = () => {
  const { visible, position, options, title } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const menuRef = useRef(null);

  const handleOptionClick = (option) => {
    if (window.onGlobalMenuSelect) {
      window.onGlobalMenuSelect(option);
    }
    dispatch(hideMenu());
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        dispatch(hideMenu());
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch]);

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className="global-menu"
          ref={menuRef}
          style={{ top: position.y, left: position.x }}
          initial={{ opacity: 0, scale: 0.8, y: -10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.8, y: -10 }}
          transition={{ duration: 0.2, ease: [0.22, 1, 0.36, 1] }}
        >
          {title && <div className="global-menu-header">{title}</div>}
          <div className="global-menu-options">
            {options.map((option, index) => (
              <div
                key={index}
                className="global-menu-item"
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default GlobalMenu;
