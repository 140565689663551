import React, { useState, useEffect } from 'react';
import './MailItem.css';
import mainEmail from '../../config';
import SupportLabels from './SupportLabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import frabaStar from './FRABA-STAR.png'; // 导入图片
 
function MailItem({ email, isSelected, selectedMailbox, onClick, isThread, threadSameAsSelectedClass }) {
  const [categories, setCategories] = useState(email.categories || []);
 
  // 同步本地状态到外部 email 对象，确保其他组件使用同一个数据源
  useEffect(() => {
    email.categories = categories;
  }, [categories, email]);
 
  const [isBeingSelected, setIsBeingSelected] = useState(false);
 
  // 高亮选中效果，延迟取消
  useEffect(() => {
    if (isSelected) {
      setIsBeingSelected(true);
      const timer = setTimeout(() => setIsBeingSelected(false), 300); // 延迟300毫秒
      return () => clearTimeout(timer);
    }
  }, [isSelected]);
 
  const {
    subject,
    from,
    toRecipients,
    ccRecipients,
    receivedDateTime,
    bodyPreview,
    hasAttachments,
    importance,
  } = email;
 
  const formattedDate = new Date(receivedDateTime).toLocaleString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    day: 'numeric',
    month: 'short',
    hour12: true,
  });
 
  const isFromMainEmail = from?.emailAddress?.address?.includes(mainEmail);
  const isCCMainEmail = ccRecipients?.some((recipient) =>
    recipient?.emailAddress?.address?.includes(mainEmail)
  );
 
  // 判断邮件是否是内部邮件
  const isInternalEmail =
    from?.emailAddress?.address?.includes('fraba') &&
    toRecipients?.every((recipient) =>
      recipient?.emailAddress?.address?.includes('fraba')
    );
 
  // 新增 isAutoReply 判断
  const autoReplyKeywords = [
    'Automatic reply',
    'Automatische Antwort',
    'Respuesta automática',
    'Réponse automatique'
  ];
 
  const isAutoReply = autoReplyKeywords.some(keyword => subject.startsWith(keyword));
 
  const getLabel = () => {
    if (selectedMailbox === 'inbox') {
      if (email.fromMailbox === 'SentItems') {
        return 'Sent'; // 显示 "Sent"
      }
      if (email.fromMailbox !== 'SentItems' && isCCMainEmail) {
        return 'CC'; // 显示 "CC"
      }
    }
    return null; // 其他情况无标签
  };
 
  const label = getLabel();
 
  const renderSenderInfo = () => {
    if (selectedMailbox === 'sentitems' && !isFromMainEmail) {
      return (
        <div className="mail-item-from-cc">
          <div className="mail-item-from">From: {from?.emailAddress?.name || 'Unknown Sender'}</div>
          {ccRecipients?.length > 0 && (
            <div className="mail-item-cc">Cc: {ccRecipients.map((recipient) => recipient.emailAddress.name).join(', ')}</div>
          )}
        </div>
      );
    } else if (
      (selectedMailbox === 'inbox' && isFromMainEmail) ||
      (selectedMailbox === 'sent' && isFromMainEmail)
    ) {
      return (
        <div className="mail-item-to-cc">
          <div className="mail-item-to">To: {toRecipients.map((recipient) => recipient.emailAddress.name).join(', ') || 'No recipients'}</div>
          {ccRecipients?.length > 0 && (
            <div className="mail-item-cc">Cc: {ccRecipients.map((recipient) => recipient.emailAddress.name).join(', ')}</div>
          )}
        </div>
      );
    } else {
      return (
        <div className="mail-item-from-cc">
          <div className="mail-item-from">From: {from?.emailAddress?.name || 'Unknown Sender'}</div>
          {ccRecipients?.length > 0 && (
            <div className="mail-item-cc">Cc: {ccRecipients.map((recipient) => recipient.emailAddress.name).join(', ')}</div>
          )}
        </div>
      );
    }
  };
 
  const hasAttachment = hasAttachments; // email.hasAttachments
  const isImportant = importance === 'high' || importance === 'High'; // email.importance
 
  const handleCategoriesChange = (newCategories) => {
    setCategories(newCategories); // 更新本地状态
  };
 
  // 根据 isThread 和 threadSameAsSelectedClass 的值计算是否为历史邮件
  const isHistory = isThread && !threadSameAsSelectedClass;
 
  // 新增：去除 bodyPreview 中所有下划线
  const cleanedBodyPreview = (bodyPreview || '').replace(/_/g, '');
 
  return (
    <div
      className={`mail-item
        ${isBeingSelected ? 'being-selected' : ''}
        ${isSelected ? 'selected' : ''}
        ${isThread ? 'thread-item' : 'email-item'}
        ${threadSameAsSelectedClass}`}
      onClick={() => onClick(email)}
    >
      <div className="mail-item-title">
        {label && <div className="mail-item-label">{label}</div>}
 
        {isInternalEmail && (
          <img src={frabaStar} alt="FRABA Star" className="mail-item-fraba-star" />
        )}
        {hasAttachment && <FontAwesomeIcon icon={faPaperclip} className="icon-attachment" />}
        {isImportant && <FontAwesomeIcon icon={faExclamationCircle} className="icon-important" />}
        <span className="mail-item-subject">{subject}</span>
        <span className="mail-item-date">{formattedDate}</span>
      </div>
      <div className="mail-item-sender">{renderSenderInfo()}</div>
      <div
        className="mail-item-body-preview"
        data-placeholder={bodyPreview ? '' : 'No Preview'}
      >
        {cleanedBodyPreview || 'No Preview'}
      </div>
      <SupportLabels
        categories={categories}
        onCategoriesChange={handleCategoriesChange}
        email={email}
        selectedMailbox={selectedMailbox}
        isHistory={isHistory}
        isAutoReply={isAutoReply}
      />
    </div>
  );
}
 
export default MailItem;
 