import React, { useEffect } from 'react';
import './App.css';
import Dashboard from './components/Dashboard/Dashboard';
import SupportPortal from './components/SupportPortal/SupportPortal';
import GlobalMenu from './components/SupportPortal/Mail/GlobalMenu';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./MicrosoftAuthConfig";
import { Provider } from 'react-redux';
import store from './store';
import { setLightTheme, setDarkTheme, setSandstoneTheme } from './components/SupportPortal/Settings/Themes'; 
import ProtectedRoute from './components/ProtectedRoute'; 
import NotFound from './components/NotFound'; 

function App() {

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    if (savedTheme === 'dark') {
      setDarkTheme(); 
    } else if (savedTheme === 'sandstone') {
      setSandstoneTheme(); 
    } else {
      setLightTheme(); 
    }
  }, []);

  return (
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <Router>
          <DynamicTitle />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            {/* 注释掉 ProtectedRoute */}
            <Route
              path="/support-portal"
              element={
                 <ProtectedRoute>  
                  <SupportPortal />
                 </ProtectedRoute> 
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>

          <GlobalMenu /> 
        </Router>
      </MsalProvider>
    </Provider>
  );
}

// Component to dynamically set the document title
function DynamicTitle() {
  const location = useLocation();

  useEffect(() => {
    // Define titles for each route
    const routeTitles = {
      '/': 'Dashboard',
      '/support-portal': 'Support Portal',
    };

    // Set the document title based on the current path
    document.title = routeTitles[location.pathname] || '404 Not Found';

  }, [location]);

  return null; // This component doesn't render anything
}

export default App;
