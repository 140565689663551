import { configureStore, combineReducers, createSlice } from '@reduxjs/toolkit';

// 定义 localStorage 键
const localStorageKey = 'user';

// 从 localStorage 加载用户信息
const loadUserFromLocalStorage = () => {
  const userData = localStorage.getItem(localStorageKey);
  return userData ? JSON.parse(userData) : { name: '', email: '', avatar: '', role: '' };
};

// 初始用户状态
const initialUserState = loadUserFromLocalStorage();

// 用户 Reducer
const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'SET_USER':
      const updatedUser = { ...state, ...action.payload };
      localStorage.setItem(localStorageKey, JSON.stringify(updatedUser)); // 同步到 localStorage
      return updatedUser;
    case 'LOGOUT_USER':
      localStorage.removeItem(localStorageKey); // 清除 localStorage
      return { name: '', email: '', avatar: '', role: '' }; // 恢复默认状态
    default:
      return state;
  }
};

// Action 定义
export const setUser = (user) => ({
  type: 'SET_USER',
  payload: user,
});

export const logoutUser = () => ({
  type: 'LOGOUT_USER',
});

// 菜单状态切片
const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    visible: false, // 菜单是否可见
    position: { x: 0, y: 0 }, // 菜单位置
    options: [], // 当前菜单选项
    onSelect: null, // 选项点击回调
    title: '', // 菜单标题
  },
  reducers: {
    showMenu: (state, action) => {
      state.visible = true;
      state.position = action.payload.position;
      state.options = action.payload.options;
      state.onSelect = action.payload.onSelect;
      state.title = action.payload.title || ''; // 设置标题，默认为空字符串
    },
    hideMenu: (state) => {
      state.visible = false;
      state.options = [];
      state.onSelect = null;
      state.title = ''; // 清除标题
    },
  },
});

// 导出菜单操作
export const { showMenu, hideMenu } = menuSlice.actions;

// 通知状态切片
const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [], // 通知队列
  },
  reducers: {
    showNotification: (state, action) => {
      state.notifications.push({
        message: action.payload.message,
        type: action.payload.type || 'normal', // 默认类型为 'normal'
        id: Date.now(), // 生成唯一 ID
      });
    },
    hideNotification: (state, action) => {
      const idToRemove = action.payload;
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== idToRemove
      );
    },
  },
});

// 导出通知操作
export const { showNotification, hideNotification } = notificationSlice.actions;

// 综合 Reducer
const rootReducer = combineReducers({
  user: userReducer, // 用户信息 reducer
  menu: menuSlice.reducer, // 菜单状态 reducer
  notification: notificationSlice.reducer, // 通知状态 reducer
});

// 创建 Redux Store
const store = configureStore({
  reducer: rootReducer,
});

export default store;
