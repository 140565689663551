// components/Mail/dbDeltaQuery.js

import { openDatabase } from './db'; // 确保路径正确
import mainEmail from '../config';

const dbPromise = openDatabase();

/**
 * 获取所有邮件的 ID
 * @returns {Promise<Set<string>>} 包含所有邮件 ID 的 Set
 */
export const getAllEmailIds = async () => {
  const db = await dbPromise;
  const tx = db.transaction(`mail-${mainEmail}-emails`, 'readonly');
  const store = tx.objectStore(`mail-${mainEmail}-emails`);

  const allEmails = await store.getAllKeys(); // 只获取键（ID）
  return new Set(allEmails);
};

/**
 * 获取 Delta Links
 * @returns {Promise<object>} Delta Links 对象
 */
export const getDeltaLinks = async () => {
  const db = await dbPromise;
  const tx = db.transaction(`mail-${mainEmail}-config`, 'readonly'); // 确保对象存储名称正确
  const store = tx.objectStore(`mail-${mainEmail}-config`);

  const deltaLinksRecord = await store.get('deltaLinks'); // 使用 'deltaLinks' 作为键
  return deltaLinksRecord?.value || {};
};

/**
 * 保存 Delta Links
 * @param {object} deltaLinks Delta Links 对象
 */
export const saveDeltaLinks = async (deltaLinks) => {
  const db = await dbPromise;
  const tx = db.transaction(`mail-${mainEmail}-config`, 'readwrite'); // 确保对象存储名称正确
  const store = tx.objectStore(`mail-${mainEmail}-config`);

  await store.put({ key: 'deltaLinks', value: deltaLinks }); // 确保对象存储使用 'key' 作为键路径
  console.log('Delta Links saved successfully.');
};

export const saveEmailsIncrementally = async (emails) => {
  try {
    const db = await dbPromise;
    const tx = db.transaction(`mail-${mainEmail}-emails`, 'readwrite');
    const store = tx.objectStore(`mail-${mainEmail}-emails`);

    await Promise.all(
      emails.map((email) =>
        store.put({
          id: email.id,
          ...email,
        })
      )
    );

    console.log(`Saved/Updated ${emails.length} emails in mail-${mainEmail}-emails`);
    await tx.done;
  } catch (error) {
    console.error('Error saving emails incrementally:', error);
    throw error;
  }
};

export const deleteEmailsIncrementally = async (emailIds) => {
  try {
    const db = await dbPromise;
    const tx = db.transaction(`mail-${mainEmail}-emails`, 'readwrite');
    const store = tx.objectStore(`mail-${mainEmail}-emails`);

    await Promise.all(
      emailIds.map((id) => store.delete(id))
    );

    console.log(`Deleted ${emailIds.length} emails from mail-${mainEmail}-emails`);
    await tx.done;
  } catch (error) {
    console.error('Error deleting emails incrementally:', error);
    throw error;
  }
};

/**
 * 更新单封邮件的分类
 * @param {string} emailId 邮件 ID
 * @param {Array} categories 新的分类
 */
export const updateEmailCategory = async (emailId, categories) => {
  const db = await dbPromise;

  const tx = db.transaction(`mail-${mainEmail}-emails`, 'readwrite'); // 确保对象存储名称正确
  const store = tx.objectStore(`mail-${mainEmail}-emails`);

  const email = await store.get(emailId);
  if (email) {
    email.categories = categories;
    await store.put(email);
    console.log(`Updated email ${emailId} categories:`, categories);
  } else {
    console.warn(`Email with ID ${emailId} not found for category update.`);
  }
};
