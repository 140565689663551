import React from 'react';
import './SupportPortal.css';
import Mail from './Mail/Mail';
import About from './Settings/About';
import Cache from './Settings/Cache';
import Themes from './Settings/Themes';
import MailInitialSSE from './MailInitialSSE'; 
import MailUpdateSSE from './MailUpdateSSE'; 

const MainContent = React.memo(({ selectedData }) => {
  return (
    <div className="main-content">

      <MailInitialSSE />
      <MailUpdateSSE />

      {selectedData.selectedItem === 'Mail' && (
        <Mail 
          selectedMailbox={selectedData.selectedMailbox || 'inbox'}
          selectedView={selectedData.selectedView || 'Traditional'}
        />
      )}
      {selectedData.selectedItem === 'Settings' && (
        <div className="settings-content">
          {selectedData.selectedSetting === 'About' && <About />}
          {selectedData.selectedSetting === 'Themes' && <Themes />}
          {selectedData.selectedSetting === 'Cache' && <Cache />}
        </div>
      )}
    </div>
  );
});

export default MainContent;
