import React, { useState, useEffect, useRef } from "react";
import { FaPaperclip, FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./MailViewAttachments.css";

function MailViewAttachments({ emailId, attachments }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(150); // 默认项宽度
  const [visibleCount, setVisibleCount] = useState(attachments.length); // 动态可见数量

  useEffect(() => {
    function handleResize() {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const gap = 15; // 项之间的间距，与CSS中的gap相同
        const minItemWidth = 120;
        const maxItemWidth = 160;

        // 计算可以放置的最大列数
        let columns = Math.max(1, Math.floor((containerWidth + gap) / (maxItemWidth + gap)));

        // 计算每个项的宽度
        let totalGap = (columns - 1) * gap;
        let calculatedItemWidth = (containerWidth - totalGap) / columns;

        // 确保宽度在最小和最大值之间
        calculatedItemWidth = Math.min(Math.max(calculatedItemWidth, minItemWidth), maxItemWidth);

        setItemWidth(calculatedItemWidth);
        setVisibleCount(columns); // 动态设置可见附件数量
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // 初始计算

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!attachments || attachments.length === 0) {
    return null;
  }

  const visibleAttachments = isExpanded
    ? attachments
    : attachments.slice(0, visibleCount); // 动态显示数量

  const shouldShowExpandButton = attachments.length > visibleCount;

  return (
    <div className="mail-view-attachments-container">
      <div className="mail-view-attachments-header">
        <div
          className="mail-view-attachments-list"
          ref={containerRef}
          style={{
            overflow: isExpanded ? "visible" : "hidden",
            maxHeight: isExpanded ? "none" : "80px", // 限制高度
          }}
        >
          {visibleAttachments.map((attachment) => (
            <div
              key={attachment.id}
              className="mail-view-attachment-item"
              style={{ flex: `0 0 ${itemWidth}px` }} // 动态设置宽度
            >
              <FaPaperclip className="attachment-icon" />
              <div className="attachment-info">
                <a
                  href={`/mail/attachment/download/${emailId}/${attachment.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="attachment-name"
                >
                  {attachment.name}
                </a>
                <div className="attachment-size">
                  {Math.ceil(attachment.size / 1024)} KB
                </div>
              </div>
            </div>
          ))}
        </div>
        {shouldShowExpandButton && (
          <button
            className="attachment-expand-button"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </button>
        )}
      </div>
    </div>
  );
}

export default MailViewAttachments;
