import React from 'react';
import './SettingsTemplate.css';

function SettingsTemplate({ title, description, buttons }) {
  return (
    <div className="settings-template-container">
      <div className="settings-template-title">{title}</div>
      <div className="settings-template-description">{description}</div>
      <div className="settings-template-buttons">
        {buttons.map((button, index) => (
          <button
            key={index}
            className={`settings-template-button ${button.className || ''}`}
            onClick={button.onClick}
          >
            {button.label}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SettingsTemplate;
