import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import './LoginScreen.css';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useDispatch, useSelector } from 'react-redux';
import { setUser, logoutUser } from '../../store';
import { Client } from '@microsoft/microsoft-graph-client';

function LoginScreen() {
  const [isClicked, setIsClicked] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { name, email, avatar } = user;

  const handleMicrosoftLogin = () => {
    instance
      .loginPopup({
        scopes: ["user.read"],
      })
      .then((response) => {
        const userProfile = response.account;
        dispatch(
          setUser({
            name: userProfile.name,
            email: userProfile.username,
            avatar: '', // 初始化头像为空，后续补充
          })
        );
      })
      .catch((error) => {
        console.error("Microsoft Login Error:", error);
      });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated && accounts.length > 0 && !avatar) {
        const account = accounts[0];

        const tokenResponse = await instance.acquireTokenSilent({
          account,
          scopes: ["User.Read"],
        });

        const client = Client.init({
          authProvider: (done) => done(null, tokenResponse.accessToken),
        });

        const userDetails = await client.api('/me').get();
        const updatedUser = {
          name: userDetails.displayName,
          email: userDetails.mail || userDetails.userPrincipalName,
          avatar: '',
          role: 'internal',
        };

        try {
          const photoResponse = await client.api('/me/photo/$value').responseType('blob').get();
          const reader = new FileReader();
          reader.onload = () => {
            updatedUser.avatar = reader.result;
            dispatch(setUser(updatedUser));
          };
          reader.readAsDataURL(photoResponse);
        } catch (error) {
          console.error("Error fetching user photo:", error);
          dispatch(setUser(updatedUser)); // 如果头像不可用，存储其他用户信息
        }
      }
    };

    fetchUserData();
  }, [isAuthenticated, accounts, instance, dispatch, avatar]);

  const handleClick = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setIsClicked(true);
    }
  };

  const handleClose = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setIsClosing(true);
      setTimeout(() => {
        setIsClicked(false);
        setIsClosing(false);
      }, 800);
    }
  };

  const handleLogout = () => {
    instance.logoutPopup().then(() => {
      dispatch(logoutUser());
    });
  };

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const maxScreenDimension = Math.max(screenWidth, screenHeight);
  const scaleValue = maxScreenDimension / 11;

  return (
    <div>
      <motion.div
        className={`login-screen-button ${isClicked ? 'clicked' : ''}`}
        animate={{
          scale: isClicked && !isClosing ? scaleValue : 1,
          backgroundColor: isClicked ? 'rgb(6, 181, 165)' : 'var(--text-color-secondary)',
        }}
        transition={{
          duration: 0.8,
          ease: [0.4, 0, 0.2, 1],
        }}
        onClick={!isClicked ? handleClick : null}
        onAnimationComplete={() => setIsAnimating(false)}
        style={{ pointerEvents: isAnimating ? 'none' : 'auto' }}
      >
        {!isClicked && (
          isAuthenticated ? (
            avatar ? (
              <img src={avatar} alt="User Avatar" className="login-screen-button-avatar" />
            ) : (
              <FontAwesomeIcon icon={faUserCircle} className="login-screen-button-avatar-icon" />
            )
          ) : (
            <FontAwesomeIcon icon={faUserCircle} className="login-screen-button-avatar-icon-sign-out" />
          )
        )}
      </motion.div>

      <AnimatePresence>
        {isClicked && (
          <motion.div
            className="login-screen-close-button"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: isClosing ? 0 : 0.2 }}
            onClick={handleClose}
            style={{ pointerEvents: isAnimating ? 'none' : 'auto' }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isClicked && !isClosing && (
          <motion.div
            className="login-screen-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.2 }}
          >
            {isAuthenticated ? (
              <div className="login-screen-user-info-container">
                <img src="/logo-dark.png" alt="Logo" className="login-screen-logo" />
                {avatar ? (
                  <img src={avatar} alt="User Avatar" className="login-screen-user-avatar" />
                ) : (
                  <FontAwesomeIcon icon={faUserCircle} className="login-screen-user-avatar-icon" />
                )}
                <div className="login-screen-user-details">
                  <div className="login-screen-user-name">
                    {name || 'Guest'}
                  </div>
                  <div className="login-screen-user-email">{email || 'No Email Available'}</div>
                </div>
                <button className="login-screen-logout-button" onClick={handleLogout}>
                  Sign Out
                </button>
              </div>
            ) : (
              <div>
                <div className="login-screen-title">Sign In</div>
                <div className="login-screen-subtitle">Internal Sign In</div>
                <div className="login-screen-options">
                  <button className="login-screen-option" onClick={handleMicrosoftLogin}>
                    <FontAwesomeIcon icon={faMicrosoft} /> Sign in with Microsoft
                  </button>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default LoginScreen;
