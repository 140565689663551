import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { hideNotification } from '../../store';
import './Notification.css';

function Notification() {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.notifications);

  // 用于在没有通知时 2 秒后显示 Logo
  const [showLogo, setShowLogo] = useState(false);

  // 监听主题变化（从 <html data-theme="light"> 或 <html data-theme="dark">）
  const [theme, setTheme] = useState(() => {
    return document.documentElement.getAttribute('data-theme') || 'light';
  });

  // 当 data-theme 属性变化时，同步更新本地的 theme 状态
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const newTheme = document.documentElement.getAttribute('data-theme');
      setTheme(newTheme || 'light');
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });

    return () => observer.disconnect();
  }, []);

  // 当没有通知时，1 秒后自动显示 Logo；有通知时则隐藏 Logo
  useEffect(() => {
    let timer;
    if (notifications.length === 0) {
      timer = setTimeout(() => {
        setShowLogo(true);
      }, 1000);
    } else {
      setShowLogo(false);
    }
    return () => clearTimeout(timer);
  }, [notifications]);

  // 如果有通知，设置自动关闭逻辑
  useEffect(() => {
    if (notifications.length === 0) {
      return;
    }
    const currentNotification = notifications[0];
    
    // still 类型通知停留 3000ms
    // 非 still 且有多个通知时，停留 500ms，否则 2000ms
    let timerDuration =
      currentNotification.type === 'still'
        ? 3000
        : notifications.length > 1
        ? 500
        : 2000;

    const timer = setTimeout(() => {
      dispatch(hideNotification(currentNotification.id));
    }, timerDuration);

    return () => clearTimeout(timer);
  }, [notifications, dispatch]);

  // 当前正在显示的通知
  const currentNotification = notifications[0];

  // 根据主题选择对应的 Logo
  const logoSrc = theme === 'dark' ? '/logo-dark.png' : '/logo-light.png';

  return (
    <div className="notification-navbar">
      <AnimatePresence>
        {currentNotification && (
          <motion.div
            key={currentNotification.id}
            className={`notification-item notification-${currentNotification.type}`}
            initial={{ y: '-100%', opacity: 0, scale: 0.9 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: '100%', opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
          >
            <p>{currentNotification.message}</p>
          </motion.div>
        )}
      </AnimatePresence>

      {/* 如果没有通知并且 showLogo 为 true，则显示 logo */}
      {(!currentNotification && showLogo) && (
        <AnimatePresence>
          <motion.div
            key="notification-logo"
            className="notification-item notification-info"
            initial={{ y: '-100%', opacity: 0, scale: 0.9 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: '100%', opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.4, ease: [0.4, 0, 0.2, 1] }}
          >
            <img 
              src={logoSrc} 
              alt="Logo Notification" 
              style={{ maxWidth: '100px' }} 
            />
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default Notification;
