import { useEffect, useState } from 'react';
import { getAllEmailsFromDB } from './db';
import './Mail.css';
 
import MailSelection from './TraditionalView/MailSelection';
import MailView from './TraditionalView/MailView';
import EmailsManager from './TraditionalView/EmailsManager';
 
function Mail({ selectedMailbox, selectedView }) {
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
 
  useEffect(() => {
    let intervalId;
 
    const fetchEmails = async () => {
      try {
        const allEmails = await getAllEmailsFromDB();
        setEmails(allEmails);
      } catch (err) {
        console.error('Failed to fetch emails:', err);
        setError('Failed to fetch emails');
      }
    };
 
    // 初次加载邮件
    fetchEmails();
 
    // 每10秒轮询更新邮件
    intervalId = setInterval(fetchEmails, 10000);
 
    // 在组件卸载时清理定时器
    return () => {
      clearInterval(intervalId);
    };
  }, []);
 
  const handleEmailSelect = (email) => {
    setSelectedEmail(email);
  };
 
  return (
    <div className="mail">
      {error && <p className="error">{error}</p>}
 
      <div className="mails-container">
        {selectedView === 'Traditional' ? (
          <>
            <div className="mails-selection">
              <EmailsManager emails={emails} selectedMailbox={selectedMailbox}>
                {(processedEmails, allEmails) => (
                  <MailSelection
                    emails={processedEmails}
                    allEmails={allEmails}
                    onEmailSelect={handleEmailSelect}
                    selectedMailbox={selectedMailbox}
                  />
                )}
              </EmailsManager>
            </div>
            <div className="mail-view">
              <MailView email={selectedEmail} />
            </div>
          </>
        ) : (
          <p>No emails available for the selected view</p>
        )}
      </div>
    </div>
  );
}
 
export default Mail;