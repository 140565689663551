import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ClickableLabel from './ClickableLabel';
import { updateStatusLabel } from '../../dbSupportLabel'; // 直接使用 updateStatusLabel
import { showNotification } from '../../../../../store'; // 引入显示通知的 action

// Old categories support: 定义旧标签优先级映射
const statusPriority = [
  {
    key: 'Done',
    match: (category) =>
      category.startsWith('Done') || category.startsWith('Finished by'),
  },
  {
    key: 'Ongoing',
    match: (category) =>
      category.startsWith('Ongoing') ||
      category.startsWith('Follow-Up') ||
      category.startsWith('Assigned to'),
  },
  { key: 'New', match: (category) => category.startsWith('New') },
];

// 根据标签列表和邮件主题判定状态
const getStatusLabel = (categories, email) => {
  // 检查是否为自动回复邮件
  if (email && typeof email.subject === 'string' && email.subject.startsWith('Automatic reply:')) {
    return 'Automail';
  }

  // 根据标签列表判定状态
  for (const { key, match } of statusPriority) {
    if (categories.some(match)) return key; // 根据优先级判定状态标签
  }

  return 'Unknown'; // 默认值
};

function StatusLabel({ categories, onStatusChange, email, selectedMailbox, isHistory, isAutoReply }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const userAbbreviationMap = {
    'Luca Suen': 'LSU',
    'Alexander Querfurth': 'AQE',
    'Delia Ana Gal': 'DGA',
  };

  const userAbbreviation = userAbbreviationMap[user.name] || 'Unknown';
  const statusOptions = ['Ongoing', 'Done'];

  const handleStatusSelect = async (newStatus) => {
    if (!email || !selectedMailbox) {
      console.warn('未提供 email 或 selectedMailbox，无法更新标签');
      return;
    }

    // 防止重复选择已存在的状态
    const currentStatusLabel = getStatusLabel(categories, email);
    if (newStatus === currentStatusLabel) {
      dispatch(
        showNotification({
          message: `Status "${newStatus}" is already applied.`,
          type: 'warning',
        })
      );
      return;
    }

    let updatedStatus = newStatus;
    if (newStatus === 'Ongoing') {
      updatedStatus = `Ongoing by ${userAbbreviation}`;
    } else if (newStatus === 'Done') {
      updatedStatus = `Done by ${userAbbreviation}`;
    } else if (newStatus === 'New') {
      updatedStatus = `New by ${userAbbreviation}`;
    }

    try {
      const updatedCategories = await updateStatusLabel(
        selectedMailbox,
        email.id,
        updatedStatus,
        userAbbreviation
      );

      if (onStatusChange) {
        onStatusChange(updatedCategories);
      }

      if (Array.isArray(updatedCategories)) {
        dispatch(
          showNotification({
            message: `Updated tags: ${updatedCategories.join(', ')}`,
            type: 'debug',
          })
        );
      } else {
        console.error('Updated categories is not an array:', updatedCategories);
      }
    } catch (error) {
      console.error('更新 StatusLabel 时出错:', error);

      dispatch(
        showNotification({
          message: 'Error updating status label',
          type: 'error',
        })
      );
    }
  };

  // 在展示菜单选项时过滤掉当前状态
  const filteredStatusOptions = statusOptions.filter(
    (option) => option !== getStatusLabel(categories, email)
  );

  // 获取当前状态标签
  let currentStatusLabel;
  let className;

  if (isHistory && isAutoReply) {
    currentStatusLabel = 'AutoReply';
    className = 'status-autoreply';
  } else if (isHistory) {
    currentStatusLabel = 'History';
    className = 'status-history';
  } else if (isAutoReply) {
    currentStatusLabel = 'AutoReply';
    className = 'status-autoreply';
  } else {
    currentStatusLabel = getStatusLabel(categories, email);
    className = `status-${currentStatusLabel.toLowerCase()}`;
  }

  return (
    <ClickableLabel
      className={className}
      options={filteredStatusOptions}
      menuTitle="Status Options"
      onSelect={handleStatusSelect}
    >
      {currentStatusLabel}
    </ClickableLabel>
  );
}

export default StatusLabel;
