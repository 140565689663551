import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import './ProtectedRoute.css';

function ProtectedRoute({ children }) {
  const isAuthenticated = useIsAuthenticated();
  const [isVerifying, setIsVerifying] = useState(true);
  const [showText, setShowText] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      setIsVerifying(false);
    } else {
      const timer = setTimeout(() => {
        setIsVerifying(false);
        setShowText(true);  // Show text and button after "verification"
      }, 2500);  // Simulate checking time

      return () => clearTimeout(timer);
    }
  }, [isAuthenticated]);

  const handleRedirect = () => {
    navigate('/');
  };

  // 正在验证中，显示加载状态
  if (isVerifying) {
    return (
      <div className="protected-route-verification-container">
        <img src="/logo-light.png" alt="Logo" className="protected-route-logo" />
        <div className="protected-route-loading-bar">
          <div className="protected-route-loading-bar-progress"></div>
        </div>
        <p className="protected-route-verification-text">ID Check</p>
      </div>
    );
  }

  // 如果验证完成但未登录，显示提示和返回按钮
  if (!isAuthenticated && !isVerifying) {
    return (
      <div className="protected-route-verification-container">
        <p className="protected-route-verification-text">
          {showText ? 'You need to be logged in to access this page.' : ''}
        </p>
        <button className="protected-route-back-home-btn" onClick={handleRedirect}>
          Return to Homepage
        </button>
      </div>
    );
  }

  // 如果验证完成并且已经登录，显示子组件
  return <>{children}</>;
}

export default ProtectedRoute;
